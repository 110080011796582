import React, { useMemo } from 'react'
import { Button, Space, TableProps, Tag } from 'antd'
import styles from '../NotificationsPage.module.scss'
import { useLocalizationContext } from '../../../utils/contexts'
import { Order, OrderState } from '../../../redux/reducers/orders/types'
import { User } from '../../../redux/reducers/home/types'
import { Moment } from 'moment/moment'
import { DATE_TIME_FORMAT } from '../../../constants'
import { useDispatch, useSelector } from 'react-redux'
import { HomeSelectors } from '../../../redux/reducers/home'
import { Link } from 'react-router-dom'
import { Links } from '../../../constants/routes'
import { OrdersActions, OrdersSelectors } from '../../../redux/reducers/orders'
import { throwNotification } from '../../../components/Notification'
import { NoticeTypes } from '../../../@types/notifications'
import { OfferType } from '../../../redux/reducers/offers/types'

export const orderStateMapping = {
    [OrderState.Draft]: {
        color: 'orange',
        text: 'draftOrder',
    },
    [OrderState.NotSigned]: {
        color: 'grey',
        text: 'notSigned',
    },
    [OrderState.Active]: {
        color: 'green',
        text: 'activeOrder',
    },
    [OrderState.Closed]: {
        color: 'black',
        text: 'closedOrder',
    },
    [OrderState.Rejected]: {
        color: 'red',
        text: 'rejectedOrder',
    },
    [OrderState.Expired]: {
        color: 'purple',
        text: 'expiredOrder',
    },
    [OrderState.Approval]: {
        color: 'grey',
        text: 'notSigned',
    },
    [OrderState.PaymentAwaiting]: {
        color: 'lightgreen',
        text: 'paymentAwaiting',
    },
}

export function useOrdersColumns(
    showActions: boolean,
    showContractLink: boolean,
    approveCallback: () => void,
    rejectCallback: () => void,
    payCallback: () => void
) {
    const { localize } = useLocalizationContext()
    const dispatch = useDispatch()
    const currentUser = useSelector(HomeSelectors.getUser)
    const paymentLoading = useSelector(OrdersSelectors.getPaymentLoading)

    const columns: TableProps<Order>['columns'] = useMemo(
        () => [
            ...(showContractLink
                ? [
                      {
                          title: localize('offer'),
                          dataIndex: 'offer',
                          key: 'offer',
                          render: (_: any, record: Order) => (
                              <Space size='middle'>
                                  <Link
                                      to={Links.ViewOffer.replace(
                                          ':id',
                                          record.offer?.id!
                                      )}
                                  >
                                      {record.offer?.id!.substring(0, 6)}
                                  </Link>
                              </Space>
                          ),
                      },
                  ]
                : []),
            {
                title: localize('order'),
                dataIndex: 'order',
                key: 'order',
                render: (_: any, record: Order) => (
                    <Space size='middle'>
                        <Link to={Links.ViewOrder.replace(':id', record?.id!)}>
                            {record?.id!.substring(0, 6)}
                        </Link>
                    </Space>
                ),
            },
            {
                title: localize('state'),
                dataIndex: 'state',
                key: 'state',
                render: (state: OrderState) => (
                    <Tag color={orderStateMapping[state].color}>
                        {localize(orderStateMapping[state].text)}
                    </Tag>
                ),
            },
            {
                title: localize('type'),
                dataIndex: 'type',
                key: 'type',
                render: (_, record: Order) => (
                    <Tag
                        color={
                            record.offer.type === OfferType.Ask ? 'blue' : 'red'
                        }
                    >
                        {(record.offer.type === OfferType.Ask
                            ? localize('ask')
                            : localize('bid')
                        ).toUpperCase()}
                    </Tag>
                ),
            },
            {
                title: localize('counterparty'),
                dataIndex: 'counterparty',
                key: 'counterparty',
                render: (counterparty: User) => counterparty?.login,
            },
            {
                title: localize('date'),
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: (value: Moment) => value.format(DATE_TIME_FORMAT),
            },
            ...(showActions
                ? [
                      {
                          title: localize('actions'),
                          key: 'action',
                          width: '100px',
                          render: (_: any, record: Order) =>
                              [
                                  OrderState.PaymentAwaiting,
                                  OrderState.Active,
                                  OrderState.Expired,
                              ].includes(record.state) ? (
                                  (
                                      record.state ===
                                      OrderState.PaymentAwaiting
                                          ? record.offer.type === OfferType.Bid
                                              ? currentUser?.id ===
                                                record.offer.owner.id
                                              : currentUser?.id ===
                                                record.counterparty.id
                                          : record.offer.type === OfferType.Ask
                                            ? currentUser?.id ===
                                              record.offer.owner.id
                                            : currentUser?.id ===
                                              record.counterparty.id
                                  ) ? (
                                      <Space
                                          className={styles.actions}
                                          size='middle'
                                      >
                                          <Button
                                              type={'primary'}
                                              disabled={paymentLoading}
                                              onClick={() => {
                                                  dispatch(
                                                      OrdersActions.payForOrder(
                                                          {
                                                              id: record.id!,
                                                              localize,
                                                              callback:
                                                                  payCallback,
                                                          }
                                                      )
                                                  )
                                              }}
                                          >
                                              {localize('pay')}
                                          </Button>
                                      </Space>
                                  ) : null
                              ) : currentUser?.id === record.offer.owner.id ? (
                                  record.state === OrderState.Draft ? (
                                      <Space
                                          className={styles.actions}
                                          size='middle'
                                      >
                                          <Button
                                              type={'primary'}
                                              onClick={() =>
                                                  dispatch(
                                                      OrdersActions.approveOrder(
                                                          {
                                                              id: record.id!,
                                                              localize,
                                                              callback:
                                                                  approveCallback,
                                                          }
                                                      )
                                                  )
                                              }
                                          >
                                              {localize('accept')}
                                          </Button>
                                          <Button
                                              type={'primary'}
                                              danger
                                              onClick={() =>
                                                  dispatch(
                                                      OrdersActions.rejectOrder(
                                                          {
                                                              id: record.id!,
                                                              localize,
                                                              callback:
                                                                  rejectCallback,
                                                          }
                                                      )
                                                  )
                                              }
                                          >
                                              {localize('reject')}
                                          </Button>
                                      </Space>
                                  ) : record.state === OrderState.Expired ? (
                                      <Space
                                          className={styles.actions}
                                          size='middle'
                                      >
                                          <Button
                                              type={'primary'}
                                              danger
                                              onClick={() =>
                                                  throwNotification(
                                                      NoticeTypes.Success,
                                                      'Тут будет интеграция'
                                                  )
                                              }
                                          >
                                              {localize(
                                                  'makeDocumentsForCourt'
                                              )}
                                          </Button>
                                      </Space>
                                  ) : null
                              ) : null,
                      },
                  ]
                : []),
        ],
        [localize, showActions, currentUser, showContractLink, paymentLoading]
    )

    return columns
}
