import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OffersState, Offer, OfferRequestPayload, OfferVars } from './types'
import {
    Paginated,
    WithCallback,
    WithLocalize,
    WithObjectId,
    WithQuery,
} from '../../../@types/common'

export const initialState: OffersState = {
    offers: undefined,
    offersLoading: false,
    specificOffer: undefined,
    specificOfferLoading: false,
    offerVars: undefined,
}

const offersSlice = createSlice({
    name: 'offers',
    initialState,
    reducers: {
        requestOfferVars: () => {},
        requestOffers: (
            state,
            action: PayloadAction<WithQuery<Partial<Paginated<{}>>>>
        ) => {},
        requestMyOffers: (
            state,
            action: PayloadAction<WithQuery<Partial<Paginated<{}>>>>
        ) => {},
        requestSpecificOffer: (
            state,
            action: PayloadAction<WithCallback<WithObjectId<{}>>>
        ) => {},
        createOffer: (
            state,
            action: PayloadAction<
                WithLocalize<WithCallback<OfferRequestPayload>>
            >
        ) => {},
        updateOffer: (
            state,
            action: PayloadAction<
                WithLocalize<WithObjectId<WithCallback<OfferRequestPayload>>>
            >
        ) => {},
        closeOffer: (
            state,
            action: PayloadAction<WithCallback<WithLocalize<WithObjectId<{}>>>>
        ) => {},
        setOffers: (
            state,
            action: PayloadAction<Paginated<Offer> | undefined>
        ) => {
            state.offers = action.payload
        },
        setOfferVars: (state, action: PayloadAction<OfferVars | undefined>) => {
            state.offerVars = action.payload
        },
        setOffersLoading: (state, action: PayloadAction<boolean>) => {
            state.offersLoading = action.payload
        },
        setSpecificOffer: (state, action: PayloadAction<Offer | undefined>) => {
            state.specificOffer = action.payload
        },
        setSpecificOfferLoading: (state, action: PayloadAction<boolean>) => {
            state.specificOfferLoading = action.payload
        },
    },
})

export const OffersActions = offersSlice.actions

interface OffersReducerState {
    offersReducer: OffersState
}

export const OffersSelectors = {
    getOffers: (state: OffersReducerState) => state.offersReducer.offers,
    getOffersLoading: (state: OffersReducerState) =>
        state.offersReducer.offersLoading,
    getSpecificOffer: (state: OffersReducerState) =>
        state.offersReducer.specificOffer,
    getSpecificOfferLoading: (state: OffersReducerState) =>
        state.offersReducer.specificOfferLoading,
    getOfferVars: (state: OffersReducerState) => state.offersReducer.offerVars,
}

export default offersSlice.reducer
