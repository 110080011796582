import React, { FC } from 'react'
import { Button } from 'antd'

export type QuestionnaireProps = {
    next: Function
}

export const Questionnaire: FC<QuestionnaireProps> = ({ next }) => {
    return (
        <div>
            <Button onClick={() => next()}>Заполнить анкету</Button>
        </div>
    )
}
