import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Links } from '../../constants/routes'
import { useDispatch } from 'react-redux'
import { HomeActions } from '../../redux/reducers/home'
import { TOKEN_KEY } from '../../constants'

export const LogoutPage: FC = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        localStorage.removeItem(TOKEN_KEY)
        dispatch(HomeActions.setIsLoggedIn(false))
        dispatch(HomeActions.setUser(undefined))
        navigate(Links.Login)
    }, [])

    return null
}
