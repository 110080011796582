import React, { FC } from 'react'
import { useLocalizationContext } from '../../utils/contexts'
import styles from './RegistrationPage.module.scss'
import { Button, Form, FormProps, Input, Typography } from 'antd'
import { Links } from '../../constants/routes'
import { useNavigate } from 'react-router-dom'
import {
    RegisterRequestPayload,
    UserType,
} from '../../redux/reducers/home/types'
import { HomeActions } from '../../redux/reducers/home'
import { useDispatch } from 'react-redux'

type State = Partial<RegisterRequestPayload>

export const RegistrationPage: FC = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { localize } = useLocalizationContext()

    const onFinish: FormProps<State>['onFinish'] = (values) => {
        dispatch(
            HomeActions.requestRegisterUser({
                ...values,
                login: values.login!,
                password: values.password!,
                type: UserType.Individual,
                localize,
            })
        )
    }

    return (
        <div className={styles.form}>
            <Form
                name='registerForm'
                wrapperCol={{ span: 16, flex: 'auto' }}
                style={{ maxWidth: 600 }}
                initialValues={{}}
                onFinish={onFinish}
                autoComplete='off'
            >
                <Form.Item<State>
                    label={localize('username')}
                    name='login'
                    rules={[
                        {
                            required: true,
                            message: localize('usernameValidation'),
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item<State>
                    label={localize('password')}
                    name='password'
                    rules={[
                        {
                            required: true,
                            message: localize('passwordValidation'),
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item<State>
                    label={localize('email')}
                    name='email'
                    rules={[
                        {
                            required: true,
                            message: localize('emailRequired'),
                        },
                        {
                            type: 'email',
                            message: localize('emailValidation'),
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button type='primary' htmlType='submit'>
                        {localize('registerButton')}
                    </Button>
                </Form.Item>
            </Form>
            <div className={styles.loginBlock}>
                <Typography.Text>
                    {localize('alreadyHaveAnAccount')}
                </Typography.Text>
                <Typography.Link onClick={() => navigate(Links.Login)}>
                    {localize('goToLogin')}
                </Typography.Link>
            </div>
        </div>
    )
}
