import React, { FC, useEffect, useRef } from 'react'
import { useLocalizationContext, useMobile } from '../../utils/contexts'
import classNames from 'classnames'
import styles from './NotificationsPage.module.scss'
import { CustomPagination, PageTitle, ResponsiveTable } from '../../components'
import { NotificationOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useOrdersColumns } from './OrdersColumns'
import { OrdersActions, OrdersSelectors } from '../../redux/reducers/orders'
import {
    DocumentsActions,
    DocumentsSelectors,
} from '../../redux/reducers/documents'

export const OrdersPage: FC = () => {
    const dispatch = useDispatch()
    const { localize } = useLocalizationContext()
    const ref = useRef<HTMLDivElement>(null)
    const isMobile = useMobile()
    const location = useLocation()
    const orders = useSelector(OrdersSelectors.getOrders)
    const documents = useSelector(DocumentsSelectors.getDocuments)

    useEffect(() => {
        dispatch(OrdersActions.requestOrders({}))
    }, [location.pathname, documents])

    const requestOrders = () => {
        dispatch(OrdersActions.requestOrders({}))
        dispatch(DocumentsActions.requestDocuments())
    }

    const columns = useOrdersColumns(
        true,
        true,
        requestOrders,
        requestOrders,
        requestOrders
    )

    return (
        <div
            className={classNames(styles.pageContainer, {
                [styles.pageContainerMobile]: isMobile,
            })}
            ref={ref}
        >
            <PageTitle
                title={localize('orders')}
                icon={<NotificationOutlined />}
            />
            <ResponsiveTable
                rowKey={'id'}
                columns={columns}
                dataSource={orders?.data || []}
                pagination={false}
            />
            {orders && orders.total > orders.perPage && (
                <CustomPagination
                    parentRef={ref}
                    showSizeChanger
                    onChange={(page, perPage) =>
                        dispatch(
                            OrdersActions.requestOrders({
                                page,
                                perPage,
                            })
                        )
                    }
                    onShowSizeChange={(page, perPage) => {
                        dispatch(
                            OrdersActions.requestOrders({
                                page,
                                perPage,
                            })
                        )
                    }}
                    defaultCurrent={orders.page}
                    total={orders.total}
                />
            )}
        </div>
    )
}
