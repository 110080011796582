import React, { FC, useCallback, useMemo, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import styles from './DocumentContent.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import {
    DocumentsActions,
    DocumentsSelectors,
} from '../../../redux/reducers/documents'
import type { PDFDocumentProxy } from 'pdfjs-dist'
import { useResizeObserver } from '@wojtekmaj/react-hooks'
import { Button, Checkbox, Form, FormProps, Input, Typography } from 'antd'
import {
    useLocalizationContext,
    useUnsignedDocumentsContext,
} from '../../../utils/contexts'
import { Link } from 'react-router-dom'

const maxWidth = 1200

export type DocumentContentProps = {
    activeId: string
}

export type State = {
    value: string
    checkBox: boolean
    formCheckbox: boolean
}

export const DocumentContent: FC<DocumentContentProps> = ({ activeId }) => {
    const dispatch = useDispatch()
    const { formatLocalize, localize } = useLocalizationContext()

    const specificDocument = useSelector(DocumentsSelectors.getSpecificDocument)
    const phone = useSelector(DocumentsSelectors.getSentOnPhone)
    const [numPages, setNumPages] = useState<number>()
    const { setVisible } = useUnsignedDocumentsContext()

    const [containerRef, setContainerRef] = useState<HTMLElement | null>(null)
    const [containerWidth, setContainerWidth] = useState<number>()

    const onResize = useCallback<ResizeObserverCallback>((entries) => {
        const [entry] = entries

        if (entry) {
            setContainerWidth(entry.contentRect.width)
        }
    }, [])

    useResizeObserver(containerRef, {}, onResize)

    function onDocumentLoadSuccess({
        numPages: nextNumPages,
    }: PDFDocumentProxy): void {
        setNumPages(nextNumPages)
    }

    const onFinish: FormProps<State>['onFinish'] = (values) => {
        dispatch(
            DocumentsActions.signWithSms({
                code: values.value,
                id: activeId,
                callback: () => {
                    setVisible(false)
                    dispatch(DocumentsActions.requestDocuments())
                },
                localize,
            })
        )
    }

    const requestSmsButton = useCallback(
        (isPrimary: boolean = true) => (
            <Button
                type={isPrimary ? 'primary' : 'default'}
                style={{ width: 'fit-content', marginTop: 12 }}
                onClick={() =>
                    dispatch(
                        DocumentsActions.requestSendSms({
                            id: activeId,
                            localize,
                        })
                    )
                }
            >
                {localize('getSms')}
            </Button>
        ),
        [activeId, localize]
    )

    const [form] = Form.useForm<State>()
    const smsValue = Form.useWatch('value', form)
    const checkBoxValue = Form.useWatch('checkBox', form)
    const formCheckboxValue = Form.useWatch('formCheckbox', form)

    const fileData = useMemo(
        () =>
            specificDocument
                ? {
                      data: Uint8Array.from(
                          atob(specificDocument.content),
                          (c) => c.charCodeAt(0)
                      ),
                  }
                : undefined,
        [specificDocument]
    )

    return (
        <div className={styles.container} ref={setContainerRef}>
            {specificDocument && specificDocument.content && (
                <Document
                    className={styles.pdfWrapper}
                    onLoadSuccess={onDocumentLoadSuccess}
                    file={fileData}
                    renderMode={'canvas'}
                >
                    {Array.from(new Array(numPages), (el, index) => (
                        <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            width={
                                containerWidth
                                    ? Math.min(containerWidth, maxWidth)
                                    : maxWidth
                            }
                        />
                    ))}
                </Document>
            )}
            <div className={styles.signBlock}>
                <Typography.Title style={{ marginTop: 12 }} level={4}>
                    {localize('signTitle')}
                </Typography.Title>
                {phone === undefined ? (
                    <>
                        <Typography.Text>
                            {localize('getSmsDescription')}
                        </Typography.Text>
                        {requestSmsButton()}
                    </>
                ) : (
                    <Form
                        form={form}
                        name='smsForm'
                        wrapperCol={{ span: 24 }}
                        className={styles.form}
                        initialValues={{}}
                        onFinish={onFinish}
                        autoComplete='off'
                    >
                        <p>
                            <Typography.Text>
                                {formatLocalize('sentOnPhone', {
                                    phone,
                                })}
                            </Typography.Text>
                        </p>
                        <Form.Item<State>
                            label={localize('codeFromSms')}
                            name='value'
                            rules={[
                                {
                                    required: true,
                                    message: localize('codeFromSmsRequired'),
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item<State>
                            className={styles.checkboxBlock}
                            name={'checkBox'}
                            style={{ width: '100%' }}
                            valuePropName={'checked'}
                        >
                            <Checkbox>
                                С информацией об условиях предлагаемого к
                                заключению посредством сервиса онлайн-
                                заимствования договора ознакомлен(а) и
                                согласен(а)
                            </Checkbox>
                        </Form.Item>
                        <Form.Item<State>
                            className={styles.checkboxBlock}
                            name={'formCheckbox'}
                            style={{ width: '100%' }}
                            valuePropName={'checked'}
                        >
                            <Checkbox>
                                С формой{' '}
                                <Link
                                    to={process.env.REACT_APP_LOAN_AGREEMENT!}
                                    target='_blank'
                                >
                                    договора займа
                                </Link>{' '}
                                ознакомлен(а) и согласен(а)
                            </Checkbox>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }}>
                            <Button
                                type='primary'
                                htmlType='submit'
                                style={{ marginRight: 12 }}
                                disabled={
                                    !smsValue ||
                                    smsValue.trim().length === 0 ||
                                    !checkBoxValue ||
                                    !formCheckboxValue
                                }
                            >
                                {localize('submit')}
                            </Button>
                            {requestSmsButton(false)}
                        </Form.Item>
                    </Form>
                )}
            </div>
        </div>
    )
}
