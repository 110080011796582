import React, { FC } from 'react'
import { PaymentComponent } from '../../../../components'

export type BePaidProps = {
    next: Function
}

export const BePaid: FC<BePaidProps> = ({ next }) => {
    return (
        <div>
            <PaymentComponent />
        </div>
    )
}
