import { all, put, StrictEffect, takeLeading, call } from 'redux-saga/effects'
import {
    Paginated,
    SagaGenerator,
    WithCallback,
    WithLocalize,
    WithObjectId,
    WithQuery,
} from '../../../@types/common'
import { ActionCreatorWithPayload, PayloadAction } from '@reduxjs/toolkit'
import { Api } from '../index'
import { ApiResponse } from 'apisauce'
import { throwNotification } from '../../../components/Notification'
import { NoticeTypes } from '../../../@types/notifications'
import { OffersActions } from '../../reducers/offers'
import {
    Offer,
    OfferRequestPayload,
    OfferVars,
} from '../../reducers/offers/types'
import moment from 'moment/moment'

export function* listOffers(
    api: Api,
    action: PayloadAction<WithQuery<Partial<Paginated<{}>>>>
) {
    const { page, perPage, query } = action.payload

    yield put(OffersActions.setOffersLoading(true))

    let result: ApiResponse<Paginated<Offer>> = yield call(
        api.listOffers,
        query,
        page,
        perPage
    )

    if (result.status === 200 && result.data) {
        for (let offer of result.data.data) {
            offer.createdAt = moment(offer.createdAt)
            offer.deletedAt = offer.deletedAt
                ? moment(offer.deletedAt)
                : undefined
            offer.updatedAt = moment(offer.updatedAt)
        }
        yield put(OffersActions.setOffers(result.data))
    }

    yield put(OffersActions.setOffersLoading(false))
}

export function* listMyOffers(
    api: Api,
    action: PayloadAction<WithQuery<Partial<Paginated<{}>>>>
) {
    const { page, perPage, query } = action.payload

    yield put(OffersActions.setOffersLoading(true))

    let result: ApiResponse<Paginated<Offer>> = yield call(
        api.listMyOffers,
        query,
        page,
        perPage
    )

    if (result.status === 200 && result.data) {
        for (let offer of result.data.data) {
            offer.createdAt = moment(offer.createdAt)
            offer.updatedAt = moment(offer.updatedAt)
            offer.deletedAt = offer.deletedAt
                ? moment(offer.deletedAt)
                : undefined
        }
        yield put(OffersActions.setOffers(result.data))
    }

    yield put(OffersActions.setOffersLoading(false))
}

export function* createOffer(
    api: Api,
    action: PayloadAction<WithLocalize<WithCallback<OfferRequestPayload>>>
) {
    const { type, amount, loanTerm, loanRate, localize, callback, count } =
        action.payload

    let result: ApiResponse<Offer> = yield call(
        api.createOffer,
        amount,
        loanTerm,
        loanRate,
        type,
        count
    )

    if (result.status === 200 && result.data) {
        throwNotification(NoticeTypes.Success, localize('offerCreated'))
        callback(result.data)
    }
}

export function* requestOfferVars(api: Api) {
    let result: ApiResponse<OfferVars> = yield call(api.offerVars)

    if (result.status === 200 && result.data) {
        yield put(OffersActions.setOfferVars(result.data))
    }
}

export function* updateOffer(
    api: Api,
    action: PayloadAction<
        WithLocalize<WithObjectId<WithCallback<OfferRequestPayload>>>
    >
) {
    const { id, type, amount, loanTerm, loanRate, localize, callback, count } =
        action.payload

    let result: ApiResponse<Offer> = yield call(
        api.updateOffer,
        id,
        amount,
        loanTerm,
        loanRate,
        type,
        count
    )

    if (result.status === 200 && result.data) {
        throwNotification(NoticeTypes.Success, localize('offerUpdated'))
        callback(result.data)
    }
}

export function* closeOffer(
    api: Api,
    action: PayloadAction<WithCallback<WithLocalize<WithObjectId<{}>>>>
) {
    const { id, localize, callback } = action.payload

    let result: ApiResponse<null> = yield call(api.closeOffer, id)

    if (result.status === 200) {
        throwNotification(NoticeTypes.Success, localize('offerClosed'))
        callback(result.data)
    }
}

export function* getSpecificOffer(
    api: Api,
    action: PayloadAction<WithCallback<WithObjectId<{}>>>
) {
    const { id, callback } = action.payload

    yield put(OffersActions.setSpecificOfferLoading(true))
    yield put(OffersActions.setSpecificOffer(undefined))
    let result: ApiResponse<Offer> = yield call(api.getSpecificOffer, id)

    if (result.status === 200 && result.data) {
        const offer = result.data
        offer.createdAt = moment(offer.createdAt)
        offer.updatedAt = moment(offer.updatedAt)
        offer.deletedAt = offer.deletedAt ? moment(offer.deletedAt) : undefined
        yield put(OffersActions.setSpecificOffer(result.data))
    } else {
        callback()
    }

    yield put(OffersActions.setSpecificOfferLoading(true))
}

export default function* groupsSagas(api: Api): SagaGenerator<StrictEffect> {
    yield all([
        yield takeLeading(
            OffersActions.createOffer as ActionCreatorWithPayload<
                WithLocalize<WithCallback<OfferRequestPayload>>
            >,
            createOffer,
            api
        ),
        yield takeLeading(
            OffersActions.updateOffer as ActionCreatorWithPayload<
                WithLocalize<WithObjectId<WithCallback<OfferRequestPayload>>>
            >,
            updateOffer,
            api
        ),
        yield takeLeading(
            OffersActions.closeOffer as ActionCreatorWithPayload<
                WithCallback<WithLocalize<WithObjectId<{}>>>
            >,
            closeOffer,
            api
        ),
        yield takeLeading(
            OffersActions.requestOffers as ActionCreatorWithPayload<
                WithQuery<Partial<Paginated<{}>>>
            >,
            listOffers,
            api
        ),
        yield takeLeading(
            OffersActions.requestMyOffers as ActionCreatorWithPayload<
                WithQuery<Partial<Paginated<{}>>>
            >,
            listMyOffers,
            api
        ),
        yield takeLeading(
            OffersActions.requestOfferVars,
            requestOfferVars,
            api
        ),
        yield takeLeading(
            OffersActions.requestSpecificOffer as ActionCreatorWithPayload<
                WithCallback<WithObjectId<{}>>
            >,
            getSpecificOffer,
            api
        ),
    ])
}
