import React from 'react'
import ReactDOM from 'react-dom/client'
import { Router } from './pages/Router'
import { Provider } from 'react-redux'
import store from './redux/store'
import './index.scss'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import 'antd/dist/reset.css'
import { ResponsiveContainer } from './components'
import 'react-pdf/dist/Page/TextLayer.css'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'pdfjs-dist/build/pdf.worker.mjs'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
    <ResponsiveContainer>
        <Provider store={store}>
            <Router />
        </Provider>
    </ResponsiveContainer>
)

serviceWorkerRegistration.register()
