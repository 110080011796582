import { all, AllEffect } from 'redux-saga/effects'
import { SagaGenerator } from '../../@types/common'
import homeSaga from './home'
import cardsSaga from './cards'
import offersSaga from './offers'
import ordersSaga from './orders'
import msiSaga from './msi'
import documentsSaga from './documents'
import questionnaireSaga from './questionnaire'
import API from '../../utils/api'

const api = API.create()

export type Api = typeof api

export default function* root(): Generator<
    AllEffect<SagaGenerator<unknown>>,
    void
> {
    yield all([
        homeSaga(api),
        cardsSaga(api),
        offersSaga(api),
        ordersSaga(api),
        msiSaga(api),
        documentsSaga(api),
        questionnaireSaga(api),
    ])
}
