import React, { ReactNode } from 'react'
import MediaQuery from 'react-responsive'
import {
    screenSizes as defaultScreenSizes,
    screenMinWidths,
    screenMaxWidths,
    ScreenSizesType,
    ScreenSize,
} from './screenSizes'

interface ResponsiveProperties {
    screenSizes?: ScreenSizesType
    children: (size: ScreenSize) => ReactNode
}

function screenSizesSequential(screenSizes: ScreenSizesType) {
    let sequential = true
    screenSizes.forEach((size, index) => {
        const defaultIndex = defaultScreenSizes.indexOf(size)
        if (
            screenSizes[index + 1] &&
            screenSizes[index + 1] !== defaultScreenSizes[defaultIndex + 1]
        ) {
            sequential = false
        }
    })
    return sequential
}

export const Responsive = ({
    screenSizes = defaultScreenSizes,
    children,
}: ResponsiveProperties) => {
    if (!screenSizesSequential(screenSizes)) {
        // eslint-disable-next-line no-console
        console.warn(
            "Warning: You should use sequential screen sizes according to this sequential: ['xs', 'sm', 'md', 'lg'] in <Responsive /> component: children will not be rendered for the missing screen sizes!!!"
        )
    }
    return (
        <>
            {screenSizes.map((size, index) => {
                if (!size) return null

                let min: number = screenMinWidths[size]
                let max: number | undefined = screenMaxWidths[size]

                if (index === 0) {
                    min = 0
                }

                if (index === screenSizes.length - 1) {
                    max = undefined
                }

                return (
                    <MediaQuery key={size} minWidth={min} maxWidth={max}>
                        {children(size)}
                    </MediaQuery>
                )
            })}
        </>
    )
}
