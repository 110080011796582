import { StrictEffect } from 'redux-saga/effects'

export type SagaGenerator<ResponseData> = Generator<
    StrictEffect,
    void,
    ResponseData
>

export type Dictionary<T = string> = {
    [key: string]: T
}

export enum Language {
    English = 'en',
    Russian = 'ru',
}

export type LanguageData = Dictionary

export type Translations = {
    [key in Language]: LanguageData
}

export type WithLocalize<T> = T & {
    localize: (value: string) => string
}

export type WithQuery<T> = T & {
    query: string
}

export type WithObjectId<T> = T & {
    id: string
}

export type WithCode<T> = T & {
    code: string
}

export type WithCallback<T> = T & {
    callback: (value?: any) => void
}

export type Paginated<T> = {
    data: T[]
    page: number
    perPage: number
    total: number
}
