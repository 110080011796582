import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { QuestionnaireData, QuestionnaireState } from './types'
import { WithCallback } from '../../../@types/common'

export const initialState: QuestionnaireState = {
    data: undefined,
    dataLoading: false,
    exists: undefined,
}

const questionnaireSlice = createSlice({
    name: 'questionnaire',
    initialState,
    reducers: {
        requestData: (state) => {},
        requestExists: (state) => {},
        updateData: (
            state,
            action: PayloadAction<WithCallback<{ data: QuestionnaireData }>>
        ) => {},
        setData: (
            state,
            action: PayloadAction<QuestionnaireData | undefined>
        ) => {
            state.data = action.payload
        },
        setDataLoading: (state, action: PayloadAction<boolean>) => {
            state.dataLoading = action.payload
        },
        setExists: (state, action: PayloadAction<boolean | undefined>) => {
            state.exists = action.payload
        },
    },
})

export const QuestionnaireActions = questionnaireSlice.actions

interface QuestionnaireReducerState {
    questionnaireReducer: QuestionnaireState
}

export const QuestionnaireSelectors = {
    getData: (state: QuestionnaireReducerState) =>
        state.questionnaireReducer.data,
    getDataLoading: (state: QuestionnaireReducerState) =>
        state.questionnaireReducer.dataLoading,
    getExists: (state: QuestionnaireReducerState) =>
        state.questionnaireReducer.exists,
}

export default questionnaireSlice.reducer
