import React, { FC, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import styles from './MsiDataPage.module.scss'
import { PageTitle } from '../../components'
import { FileOutlined } from '@ant-design/icons'
import { useLocalizationContext, useMobile } from '../../utils/contexts'
import { useSelector } from 'react-redux'
import { MsiSelectors } from '../../redux/reducers/msi'
import { Button, Input, Space, Typography } from 'antd'
import {
    AddressType,
    IdDocumentType,
    MsiData,
    MsiLegalData,
} from '../../redux/reducers/msi/types'
import { useNavigate } from 'react-router-dom'
import { Links } from '../../constants/routes'
import { DATE_FORMAT } from '../../constants'
import { countries } from '../../locales'
import { Alert } from 'antd'
import moment from 'moment'
import { HomeSelectors } from '../../redux/reducers/home'
import { USER_INDIVIDUAL } from '../../@types/accesses'
const { Text, Title } = Typography


export const addressToString = (address: AddressType) => {
    return [
        address.locality_type ? address.locality_type + ' ' : undefined,
        address.locality ? address.locality + ', ' : undefined,
        address.street_type ? address.street_type + ' ' : undefined,
        address.street ? address.street + ' ' : undefined,
        address.house
            ? address.house + (address.building ? '' : ' ')
            : undefined,
        address.building ? address.building + ', ' : undefined,
        address.apartment ? address.apartment + ', ' : undefined,
        address.postal_code,
    ]
        .filter((x) => !!x)
        .join('')
}

export const nameToString = (data: MsiData['data']['subject']) => {
    if (data.name_ru) {
        return [
            data.name_ru.family_name_ru,
            data.name_ru.given_name_ru,
            data.name_ru.middle_name_ru,
        ]
            .filter((x) => !!x)
            .join(' ')
    } else if (data.name_be) {
        return [
            data.name_be.family_name_be,
            data.name_be.given_name_be,
            data.name_be.middle_name_be,
        ]
            .filter((x) => !!x)
            .join(' ')
    } else if (data.name_en) {
        return data.name_en.family_name_en + ' ' + data.name_en.given_name_en
    } else {
        return ''
    }
}

export const isDataComplete = (data: MsiData['data']) => {
    const isResident = data.subject?.resident === true
    const isName = !!nameToString(data.subject)
    const isDocument = (!!data.id_document?.seriesNumber) && (!!data.id_document.expireDate) && (data.id_document.expireDate > moment.utc())
    // TODO: If empty get it from session_info.phone if exists
    const isPhone = !!data.contact?.phoneSMSVerification
    return isResident && isName && isDocument && isPhone
}

export const isLegalDataComplete = (data: MsiLegalData['data']) => {
    return true
}

const idDocumentMappings = {
    [IdDocumentType.BelarusPassport]: 'Паспорт Республики Беларусь',
    [IdDocumentType.BelarusResidentCard]: 'Вид на жительство Республики Беларусь',
    [IdDocumentType.IdCardBelarus]: 'Идентификационная карта Республики Беларусь',
    [IdDocumentType.BiometricResidentCard]: 'Биометрический вид на жительство в Республике Беларусь иностранного гражданина',
    [IdDocumentType.BiometricResidentCardWithoutCitizenship]: 'Биометрический вид на жительство в Республике Беларусь лица без гражданства',
}

const idLegalTypeMapping = {
    '': 'Не определено',
    'business': 'Юридическое Лицо',
    'entrepreneur': 'Индивидуальный Предприниматель',
    'government': 'Орган Государственной Власти и Управления'
}

export const MsiDataPage: FC = () => {
    const isMobile = useMobile()
    const navigate = useNavigate()
    const { localize } = useLocalizationContext()
    const msiData = useSelector(MsiSelectors.getMsiData)
    const msiLegalData = useSelector(MsiSelectors.getMsiLegalData)
    const msiDataLoading = useSelector(MsiSelectors.getMsiDataLoading)
    const accesses = useSelector(HomeSelectors.getAccesses)
    const [isLoaded, setIsLoaded] = useState(false)

    const [dataIncomplete, setDataIncomplete] = useState(false)
    const [dataEnabled, setDataEnabled] = useState(true)

    const extraRender = false

    useEffect(() => {
        if (msiData && accesses && accesses[USER_INDIVIDUAL]) {
            setIsLoaded(true)
            setDataIncomplete(!isDataComplete(msiData.data))
            setDataEnabled(msiData.enabled === true)
        } else if (msiLegalData && accesses && !accesses[USER_INDIVIDUAL]) {
            setIsLoaded(true)
            setDataIncomplete(!isLegalDataComplete(msiLegalData.data))
            setDataEnabled(msiLegalData.enabled === true)
        }
    }, [msiData, msiLegalData, accesses])

    return (
        <div
            className={classNames(styles.pageContainer, {
                [styles.pageContainerMobile]: isMobile,
            })}
        >
            <PageTitle title={'Данные из МСИ'} icon={<FileOutlined />} />
            {isLoaded && accesses && accesses[USER_INDIVIDUAL] && (
                <>
                    {(!dataEnabled) && (
                        <Alert
                            type='error'
                            banner={true}
                            message='Проблема с идентификацией. Обратитесь в службу поддержки платформы.'>
                        </Alert>
                    )}
                    {dataIncomplete && (
                        <Alert
                            type='warning'
                            banner={true}
                            message='Отсутствуют обязательные данные для идентификации. Обратитесь в службу поддержки платформы.'>
                        </Alert>
                    )}
                    {msiData ? (
                        <Space direction={'vertical'} style={{ width: '100%' }}>
                            <Title level={3}>Личные данные</Title>
                            <Space
                                direction={'horizontal'}
                                style={{ width: '100%' }}
                            >
                                <Space
                                    direction={'vertical'}
                                    style={{ width: '100%' }}
                                >
                                    <Text>Фамилия:</Text>
                                    <Input
                                        disabled
                                        value={
                                            msiData.data.subject.name_ru
                                                ?.family_name_ru ||
                                            msiData.data.subject.name_be
                                                ?.family_name_be ||
                                            msiData.data.subject.name_en
                                                ?.family_name_en ||
                                            ''
                                        }
                                    />
                                </Space>
                                <Space
                                    direction={'vertical'}
                                    style={{ width: '100%' }}
                                >
                                    <Text>Имя:</Text>
                                    <Input
                                        disabled
                                        value={
                                            msiData.data.subject.name_ru
                                                ?.given_name_ru ||
                                            msiData.data.subject.name_be
                                                ?.given_name_be ||
                                            msiData.data.subject.name_en
                                                ?.given_name_en ||
                                            ''
                                        }
                                    />
                                </Space>
                                <Space
                                    direction={'vertical'}
                                    style={{ width: '100%' }}
                                >
                                    <Text>Отчество:</Text>
                                    <Input
                                        disabled
                                        value={
                                            msiData.data.subject.name_ru
                                                ?.middle_name_ru ||
                                            msiData.data.subject.name_be
                                                ?.middle_name_be ||
                                            ''
                                        }
                                    />
                                </Space>
                            </Space>
                            {extraRender && (
                                <Space direction={'horizontal'}>
                                    <Space direction={'vertical'}>
                                        <Text>Дата рождения:</Text>
                                        <Input
                                            disabled
                                            value={msiData.data.subject.birthdate.format(
                                                DATE_FORMAT
                                            )}
                                        />
                                    </Space>
                                </Space>
                            )}                            
                            {extraRender && msiData.data.subject.birth_place && (
                                <Space
                                    direction={'horizontal'}
                                    style={{ width: '100%' }}
                                >
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>Место рождения:</Text>
                                        <Input
                                            disabled
                                            value={addressToString(
                                                msiData.data.subject.birth_place
                                            )}
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                </Space>
                            )}
                            {extraRender && msiData.data.subject.address && (
                                <Space
                                    direction={'horizontal'}
                                    style={{ width: '100%' }}
                                >
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>Место жительства:</Text>
                                        <Input
                                            disabled
                                            value={addressToString(
                                                msiData.data.subject.address
                                            )}
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                </Space>
                            )}
                            {msiData.data.id_document.country && (
                                <Space
                                    direction={'horizontal'}
                                    style={{ width: '100%' }}
                                >
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>Гражданство:</Text>
                                        <Input
                                            disabled
                                            value={
                                                countries.data.find(
                                                    (x) =>
                                                        x.code ===
                                                        msiData.data.id_document
                                                            .country
                                                )?.fullname
                                            }
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>Резидент Республики Беларусь:</Text>
                                        <Input
                                            disabled
                                            value={msiData.data.subject.resident ? "Да" : "Нет"}
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                </Space>
                            )}
                            <br />
                            <Title level={3}>
                                Информация о документе, удостоверяющем личность
                            </Title>
                            {msiData.data.subject.name_en && (
                                <Space
                                    direction={'horizontal'}
                                    style={{ width: '100%' }}
                                >
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>
                                            Фамилия, собственное имя на латинице
                                            в соответствии с документом,
                                            удостоверяющим личность
                                        </Text>
                                        <Input
                                            disabled
                                            value={
                                                msiData.data.subject.name_en
                                                    .family_name_en +
                                                ' ' +
                                                msiData.data.subject.name_en
                                                    .given_name_en
                                            }
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                </Space>
                            )}
                            {msiData.data.id_document && (
                                <Space
                                    direction={'horizontal'}
                                    style={{ width: '100%' }}
                                >
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>
                                            Вид документа, удостоверяющего
                                            личность
                                        </Text>
                                        <Input
                                            disabled
                                            value={
                                                idDocumentMappings[
                                                    msiData.data.id_document
                                                        .type
                                                ]
                                            }
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                </Space>
                            )}
                            {msiData.data.id_document && (
                                <Space
                                    direction={'horizontal'}
                                    style={{ width: '100%' }}
                                >
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>Страна выдачи</Text>
                                        <Input
                                            disabled
                                            value={
                                                countries.data.find(
                                                    (x) =>
                                                        x.code ===
                                                        msiData.data.id_document.country
                                                )?.fullname
                                            }
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                </Space>
                            )}
                            {msiData.data.id_document && (
                                <Space
                                    direction={'horizontal'}
                                    style={{ width: '100%' }}
                                >
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>
                                            Орган, выдавший документ,
                                            удостоверяющий личность
                                        </Text>
                                        <Input
                                            disabled
                                            value={
                                                msiData.data.id_document
                                                    .authority
                                            }
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>
                                            Реквизиты документа, удостоверяющего
                                            личность (идентификационный номер)
                                        </Text>
                                        <Input
                                            disabled
                                            value={
                                                msiData.data.national_id_number
                                            }
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                </Space>
                            )}
                            {msiData.data.id_document && (
                                <Space
                                    direction={'horizontal'}
                                    style={{ width: '100%' }}
                                >
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>Серия</Text>
                                        <Input
                                            disabled
                                            value={
                                                msiData.data.id_document.series
                                            }
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>Номер</Text>
                                        <Input
                                            disabled
                                            value={
                                                msiData.data.id_document.number
                                            }
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                </Space>
                            )}
                            {msiData.data.id_document && (
                                <Space
                                    direction={'horizontal'}
                                    style={{ width: '100%' }}
                                >
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>Дата выдачи</Text>
                                        <Input
                                            disabled
                                            value={(!!msiData.data.id_document.issueDate && msiData.data.id_document?.issueDate.toString().toLowerCase() !== 'invalid date') ? msiData.data.id_document.issueDate.format(DATE_FORMAT) : ''}
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>Срок действия</Text>
                                        <Input
                                            disabled
                                            value={(!!msiData.data.id_document.expireDate && msiData.data.id_document?.expireDate.toString().toLowerCase() !== 'invalid date') ? msiData.data.id_document.expireDate.format(DATE_FORMAT): ''}
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                </Space>
                            )}
                            <br />
                            <Title level={3}>Контактные данные</Title>
                            {msiData.data.contact && (
                                <Space
                                    direction={'horizontal'}
                                    style={{
                                        width: '100%',
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>
                                            Номер мобильного телефона,
                                            использующийся для аутентификации
                                        </Text>
                                        <Input
                                            disabled
                                            value={
                                                msiData.data.contact.phoneSMSVerification || ''
                                            }
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>
                                            Иные номера контактных телефонов
                                        </Text>
                                        <Input
                                            disabled
                                            value={msiData.data.contact.phones
                                                .filter(
                                                    (x) =>
                                                        x !==
                                                        msiData.data.contact.phoneSMSVerification || ''
                                                )
                                                .join(', ')}
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>Адрес электронной почты</Text>
                                        <Input
                                            disabled
                                            value={
                                                msiData.data.contact.emails
                                                    .length > 0
                                                    ? msiData.data.contact
                                                          .emails[0]
                                                    : ''
                                            }
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                </Space>
                            )}
                        </Space>
                    ) : (
                        <Space direction={'vertical'}>
                            <Text>{localize('noVerification')}</Text>
                            <Button
                                type={'primary'}
                                disabled={msiDataLoading}
                                onClick={() => navigate(Links.Wizard)}
                            >
                                {localize('goToVerification')}
                            </Button>
                        </Space>
                    )}
                </>
            )}
            {isLoaded && accesses && !accesses[USER_INDIVIDUAL] && (
                <>
                    {(!dataEnabled) && (
                        <Alert
                            type='error'
                            banner={true}
                            message='Проблема с идентификацией. Обратитесь в службу поддержки платформы.'>
                        </Alert>
                    )}
                    {dataIncomplete && (
                        <Alert
                            type='warning'
                            banner={true}
                            message='Отсутствуют обязательные данные для идентификации. Обратитесь в службу поддержки платформы.'>
                        </Alert>
                    )}
                    {msiLegalData ? (
                        <Space direction={'vertical'} style={{ width: '100%' }}>
                            <Title level={3}>Сведения о реквизитах</Title>
                            <Space
                                direction={'horizontal'}
                                style={{ width: '100%' }}
                            >
                                <Space
                                    direction={'vertical'}
                                    style={{ width: '100%' }}
                                >
                                    <Text>Полное наименование организации</Text>
                                    <Input
                                        disabled
                                        value={
                                            msiLegalData.data.legalPersonRequisites?.orgName || ''
                                        }
                                        style={{ width: '100%' }}
                                    />
                                </Space>
                            </Space>
                            <Space
                                direction={'horizontal'}
                                style={{ width: '100%' }}
                            >
                                <Space
                                    direction={'vertical'}
                                    style={{ width: '100%' }}
                                >
                                    <Text>Сокращенное наименование организации</Text>
                                    <Input
                                        disabled
                                        value={
                                            msiLegalData.data.legalPersonRequisites?.orgShortName || ''
                                        }
                                        style={{ width: '100%' }}
                                    />
                                </Space>
                            </Space>
                            <Space
                                direction={'horizontal'}
                                style={{ width: '100%' }}
                            >
                                <Space
                                    direction={'vertical'}
                                    style={{ width: '100%' }}
                                >
                                    <Text>Тип организации</Text>
                                    <Input
                                        disabled
                                        value={
                                            Object.entries(idLegalTypeMapping).filter(
                                                x => x[0] === msiLegalData.data.legalPersonRequisites?.type || ''
                                            )[0][1]
                                        }
                                        style={{ width: '100%' }}
                                    />
                                </Space>
                                <Space
                                    direction={'vertical'}
                                    style={{ width: '100%' }}
                                >
                                    <Text>Статус</Text>
                                    <Input
                                        disabled
                                        value={
                                            msiLegalData.data.legalPersonRequisites?.status || ''
                                        }
                                        style={{ width: '100%' }}
                                    />
                                </Space>
                                <Space
                                    direction={'vertical'}
                                    style={{ width: '100%' }}
                                >
                                    <Text>Учетный номер плательщика</Text>
                                    <Input
                                        disabled
                                        value={
                                            msiLegalData.data.legalPersonRequisites?.unp || ''
                                        }
                                        style={{ width: '100%' }}
                                    />
                                </Space>
                            </Space>
                            <br />
                            <Title level={3}>Контактные данные</Title>
                            {msiLegalData.data.legalPersonRequisites?.contacts && (
                                <Space
                                    direction={'horizontal'}
                                    style={{
                                        width: '100%',
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>
                                            Номер мобильного телефона для SMS подтверждения
                                        </Text>
                                        <Input
                                            disabled
                                            value={
                                                msiLegalData.data.legalPersonRequisites?.contacts?.smsVerificationPhones || ''
                                            }
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>
                                            Иные номера контактных телефонов
                                        </Text>
                                        <Input
                                            disabled
                                            value={(
                                                    (msiLegalData.data.legalPersonRequisites?.contacts?.mobilePhones || []).concat(
                                                        msiLegalData.data.legalPersonRequisites?.contacts?.cityPhones || []
                                                    )
                                                )
                                                .filter(
                                                    (x) =>
                                                        x !==
                                                        msiLegalData.data.legalPersonRequisites?.contacts?.smsVerificationPhones || ''
                                                )
                                                .join(', ')}
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                    <Space
                                        direction={'vertical'}
                                        style={{ width: '100%' }}
                                    >
                                        <Text>Адреса электронной почты</Text>
                                        <Input
                                            disabled
                                            value={
                                                (msiLegalData.data.legalPersonRequisites?.contacts?.emails || []).length > 0
                                                    ? msiLegalData.data.legalPersonRequisites?.contacts?.emails?.join(', ')
                                                    : ''
                                            }
                                            style={{ width: '100%' }}
                                        />
                                    </Space>
                                </Space>
                            )}
                        </Space>
                    ) : (
                        <Space direction={'vertical'}>
                            <Text>{localize('noVerification')}</Text>
                            <Button
                                type={'primary'}
                                disabled={msiDataLoading}
                                onClick={() => navigate(Links.Wizard)}
                            >
                                {localize('goToVerification')}
                            </Button>
                        </Space>
                    )}
                </>
            )}
        </div>
    )
}
