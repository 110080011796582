import moment, { Moment } from 'moment'

export const formatDate = (
    date: Moment,
    localize: (v: string) => string
): string => {
    if (date.get('days') === moment().get('days')) {
        return `${localize('today')}, ${date.format('hh:mm A')}`
    } else {
        return date.format('DD/MM/YYYY hh:mm A')
    }
}
