import React, { FC, useEffect, useMemo, useState } from 'react'
import { Modal, Tabs, TabsProps } from 'antd'
import styles from './DocumentsModal.module.scss'
import {
    useLocalizationContext,
    useUnsignedDocumentsContext,
} from '../../utils/contexts'
import { useDispatch, useSelector } from 'react-redux'
import {
    DocumentsActions,
    DocumentsSelectors,
} from '../../redux/reducers/documents'
import { DocumentContent } from './DocumentContent'

export const DocumentsModal: FC = () => {
    const dispatch = useDispatch()
    const { localize, formatLocalize } = useLocalizationContext()
    const { setVisible, visible } = useUnsignedDocumentsContext()

    const documents = useSelector(DocumentsSelectors.getDocuments)
    const [activeKey, setActiveKey] = useState<string>()

    useEffect(() => {
        dispatch(DocumentsActions.setSentOnPhone(undefined))
    }, [activeKey])

    useEffect(() => {
        if (activeKey) {
            dispatch(
                DocumentsActions.requestSpecificDocument({
                    id:
                        documents?.find((x) => x.id === activeKey)
                            ?.documentId || '',
                })
            )
        }
    }, [activeKey])

    useEffect(() => {
        if (documents && documents.length > 0) {
            setActiveKey(documents[0].id)
        }
    }, [documents])

    const items: TabsProps['items'] = useMemo(
        () =>
            documents?.map((x) => ({
                key: x.id,
                label: formatLocalize('documentTitle', {
                    number: x.id.substring(0, 3),
                }),
                children: <DocumentContent activeId={x.id} />,
            })),
        [formatLocalize, documents]
    )

    return (
        <Modal
            title={localize('signDocuments')}
            open={visible}
            onCancel={() => {
                dispatch(DocumentsActions.setSentOnPhone(undefined))
                setVisible(false)
            }}
            width={800}
            footer={[]}
        >
            <div className={styles.container}>
                <Tabs
                    activeKey={activeKey}
                    items={items}
                    onChange={(value) => setActiveKey(value)}
                />
            </div>
        </Modal>
    )
}
