import React, { FC, PropsWithChildren } from 'react'
import moment from 'moment'
import LocalizedStrings from 'react-localization'
import {
    FormatLocalize,
    ILocalizationContext,
    LocalizationContext,
} from '../contexts'
import { Language, Translations } from '../../@types/common'

interface LocalizationProviderProps {
    translation: Translations
    locale: Language
}

const updateStrings = (props: LocalizationProviderProps) => {
    const result = new LocalizedStrings(props.translation, {
        pseudo: false,
        pseudoMultipleLanguages: true,
        logsEnabled: false,
    })
    result.setLanguage(props.locale)
    return result
}

export const LocalizationProvider: FC<
    PropsWithChildren<LocalizationProviderProps>
> = (props) => {
    let strings = updateStrings(props)
    moment.locale(props.locale)

    const pluralize = (count: number, one: string, two: string): string => {
        return count > 1 ? two : one
    }

    const localize = (key: string) =>
        strings && strings[key] && strings[key] !== ''
            ? strings[key]
            : '$' + key + '$'

    const formatLocalize: FormatLocalize = (key, params) =>
        params
            ? (strings.formatString(localize(key), params) as any)
            : localize(key)

    const value: ILocalizationContext = {
        locale: props.locale,
        strings,
        localize,
        formatLocalize,
        addTranslation: (voc: Translations) => {
            for (let k in props.translation) {
                const key = k as Language
                if (key in voc) {
                    props.translation[key] = {
                        ...props.translation[key],
                        ...voc[key],
                    }
                }
            }
            strings = updateStrings(props)
        },
        formatDate: (date, format) =>
            date ? moment(date).format(format || 'L') : null,
        formatUTCDate: (date, format) =>
            date ? moment.utc(date).format(format || 'L') : null,
        format: (string, params) =>
            params ? (strings.formatString(string, params) as any) : string,
        formatLocalizePluralize: ({ key, params, count, two, five }) =>
            count
                ? pluralize(
                      count,
                      formatLocalize(key, params),
                      formatLocalize(two, params)
                  )
                : formatLocalize(key, params),
        pluralize: (count, one, two, five) =>
            pluralize(count, localize(one), localize(two)),
    }

    return (
        <LocalizationContext.Provider value={value}>
            {props.children}
        </LocalizationContext.Provider>
    )
}
