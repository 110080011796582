import React, { FC } from 'react'
import { Pagination, PaginationProps } from 'antd'

export type CustomPaginationProps = PaginationProps & {
    parentRef: React.RefObject<HTMLDivElement | null>
}

export const CustomPagination: FC<CustomPaginationProps> = (props) => {
    return (
        <Pagination
            {...props}
            onChange={(...obj) => {
                props?.onChange?.(...obj)
                props.parentRef.current?.scrollTo(0, 0)
            }}
        />
    )
}
