import { notification } from 'antd'
import { ReactNode } from 'react'
import { NoticeMessages, NoticeTypes } from '../../@types/notifications'
import { Dictionary } from '../../@types/common'

const noticeMessage = {
    [NoticeMessages.Success]: 'Success',
    [NoticeMessages.Error]: 'Error',
}

export const throwNotification = (
    type: NoticeTypes,
    description?: ReactNode | string | Dictionary<string[]>,
    message?: string,
    duration: number = 3.5
) => {
    notification[type]({
        message: message || noticeMessage[type],
        description:
            typeof description === 'string' ? (
                description
            ) : (
                <div>
                    {Object.keys(description as Dictionary<string[]>).map(
                        (key) => (
                            <div key={key}>
                                <b>{key}</b>:{' '}
                                <span>
                                    {(description as Dictionary<string[]>)[
                                        key
                                    ].join(', ')}
                                </span>
                            </div>
                        )
                    )}
                </div>
            ),
        duration,
    })
}
