import React, { FC } from 'react'
import { Button } from 'antd'

export type MSIProps = {
    next: Function
}

export const MSI: FC<MSIProps> = ({ next }) => {
    return (
        <div>
            <Button onClick={() => next()}>Перейти в МСИ</Button>
        </div>
    )
}
