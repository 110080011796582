import React, { useContext } from 'react'

export type UnsignedDocumentsContextState = {
    visible: boolean
    setVisible: (visible: boolean) => void
}

export const UnsignedDocumentsContext =
    React.createContext<UnsignedDocumentsContextState>({
        visible: false,
        setVisible: () => {},
    })

export const useUnsignedDocumentsContext = () =>
    useContext(UnsignedDocumentsContext)
