import { all, put, StrictEffect, takeLeading, call } from 'redux-saga/effects'
import { SagaGenerator } from '../../../@types/common'
import { Api } from '../index'
import { ApiResponse } from 'apisauce'
import { MsiActions } from '../../reducers/msi'
import { MsiData, MsiLegalData } from '../../reducers/msi/types'
import moment from 'moment'

export function* requestMsiExists(api: Api) {
    let result: ApiResponse<{ exists: boolean }> = yield call(
        api.isMsiDataExists
    )

    if (result.status === 200 && result.data) {
        yield put(MsiActions.setMsiExists(true))
    } else {
        yield put(MsiActions.setMsiExists(false))
    }
}

export function* requestMsiUrl(api: Api) {
    yield put(MsiActions.setMsiUrlLoading(true))

    let result: ApiResponse<{ url: string }> = yield call(api.getMsiURL)

    if (result.status === 200 && result.data) {
        yield put(MsiActions.setMsiUrl(result.data.url))
    }

    yield put(MsiActions.setMsiUrlLoading(false))
}

export function* requestMsiData(api: Api) {
    yield put(MsiActions.setMsiDataLoading(true))

    let result: ApiResponse<any> = yield call(api.getMsiData)

    const commonDateFormat = 'YYYYMMDD'
    if (result.status === 200 && result.data && !result.data.statusCode) {
        if (result.data?.data?.id_document) {
            let msiData: MsiData = {
                createdAt: moment(result.data.createdAt),
                deletedAt: result.data.deletedAt
                    ? moment(result.data.deletedAt)
                    : null,
                updatedAt: moment(result.data.updatedAt),
                user: result.data.user,
                id: result.data.id,
                enabled: result.data.enabled,
                data: {
                    ...result.data.data,
                    subject: result.data.data.subject
                        ? {
                            ...result.data.data.subject,
                            birthdate: moment(
                                result.data.data.subject.birthdate,
                                commonDateFormat
                            ),
                        }
                        : undefined,
                    esiful_residence_place: result.data.data.esiful_residence_place
                        ? {
                            ...result.data.data.esiful_residence_place,
                            reg_date: moment(
                                result.data.data.esiful_residence_place.reg_date
                            ),
                        }
                        : undefined,
                    id_document: result.data.data.id_document
                        ? {
                            ...result.data.data.id_document,
                            expireDate: moment(
                                result.data.data.id_document.expireDate,
                                commonDateFormat
                            ),
                            issueDate: moment(
                                result.data.data.id_document.issueDate,
                                commonDateFormat
                            ),
                        }
                        : undefined,
                    user_registration_info: result.data.data.user_registration_info
                        ? {
                            ...result.data.data.user_registration_info,
                            msi_registration: {
                                ...result.data.data.user_registration_info
                                    .msi_registration,
                                msi_registration_date: moment(
                                    result.data.data.user_registration_info
                                        .msi_registration.msi_registration_date,
                                    commonDateFormat
                                ),
                            },
                            ra_registration_last_update: {
                                last_ra_date: moment(
                                    result.data.data.user_registration_info
                                        .ra_registration_last_update.last_ra_date,
                                    commonDateFormat
                                ),
                                last_update_date: moment(
                                    result.data.data.user_registration_info
                                        .ra_registration_last_update
                                        .last_update_date,
                                    commonDateFormat
                                ),
                            },
                            ra_registration_primary: {
                                primary_ra_date: moment(
                                    result.data.data.user_registration_info
                                        .ra_registration_primary.primary_ra_date,
                                    commonDateFormat
                                ),
                                primary_submission_date: moment(
                                    result.data.data.user_registration_info
                                        .ra_registration_primary
                                        .primary_submission_date,
                                    commonDateFormat
                                ),
                            },
                        }
                        : undefined,
                    validation: {
                        result: result.data?.data?.validation?.result
                    }
                },
            }
            yield put(MsiActions.setMsiData(msiData))
        } else if (result.data?.data?.legalPersonRequisites) {
            let msiLegalData: MsiLegalData = {
                createdAt: moment(result.data.createdAt),
                deletedAt: result.data.deletedAt
                    ? moment(result.data.deletedAt)
                    : null,
                updatedAt: moment(result.data.updatedAt),
                user: result.data.user,
                id: result.data.id,
                enabled: result.data.enabled,
                data: {
                    ...result.data.data,
                }
            }
            yield put(MsiActions.setMsiLegalData(msiLegalData))
        }
    }

    yield put(MsiActions.setMsiDataLoading(false))
}

export default function* groupsSagas(api: Api): SagaGenerator<StrictEffect> {
    yield all([
        yield takeLeading(MsiActions.requestMsiExists, requestMsiExists, api),
        yield takeLeading(MsiActions.requestMsiURL, requestMsiUrl, api),
        yield takeLeading(MsiActions.requestMsiData, requestMsiData, api),
    ])
}
