import en from './en.json'
import ru from './ru.json'
import { Translations } from '../@types/common'
import countries from './countries.json'

export { countries }

export default {
    en,
    ru,
} as Translations
