import apisauce from 'apisauce'
import {
    getAuthHeader,
    headers as commonHeaders,
    requestCreate,
    RequestMethod,
} from '../helpers'
import { env } from '../../env'
import {
    AuthResponse,
    RegStatus,
    User,
    UserType,
} from '../../redux/reducers/home/types'
import { Card } from '../../redux/reducers/cards/types'
import { Dictionary, Paginated } from '../../@types/common'
import { Offer, OfferType, OfferVars } from '../../redux/reducers/offers/types'
import { Order } from '../../redux/reducers/orders/types'
import { Document } from '../../redux/reducers/documents/types'
import { TabIndex } from '../../pages/WizardPage'
import { QuestionnaireData } from '../../redux/reducers/questionnaire/types'

const create = () => {
    const coreApi = () =>
        apisauce.create({
            headers: {
                ...commonHeaders,
                Authorization: getAuthHeader(),
            },
            baseURL: env.REACT_APP_CORE_API_URL,
        })

    const request = requestCreate(coreApi)

    const logIn = (username: string, password: string) =>
        request<AuthResponse>({
            path: `/auth/login`,
            params: {
                login: username,
                password,
            },
            method: RequestMethod.POST,
        })

    const registerUser = (
        username: string,
        password: string,
        type: UserType,
        email?: string,
        number?: string
    ) =>
        request<AuthResponse>({
            path: `/auth/registration`,
            params: {
                login: username,
                password,
                type,
                email,
                number,
            },
            method: RequestMethod.POST,
        })

    const getCurrentUser = () =>
        request<AuthResponse>({
            path: `/auth/me`,
            method: RequestMethod.GET,
        })

    const getUserAccesses = () =>
        request<Dictionary<boolean>>({
            path: `/user/accesses`,
            method: RequestMethod.GET,
        })

    const deleteCard = (id: string) =>
        request({
            path: `/card/${id}`,
            method: RequestMethod.DELETE,
        })

    const listCards = (page?: number, perPage?: number) =>
        request<Paginated<Card>>({
            path: `/card`,
            method: RequestMethod.GET,
            params: {
                page,
                perPage,
            },
        })

    const createOffer = (
        amount: number,
        loanTerm: number,
        loanRate: number,
        type: OfferType,
        count: number
    ) =>
        request<Offer>({
            path: `/offer`,
            method: RequestMethod.POST,
            params: {
                amount,
                loanTerm,
                loanRate,
                type,
                count,
            },
        })

    const updateOffer = (
        id: string,
        amount: number,
        loanTerm: number,
        loanRate: number,
        type: OfferType,
        count: number
    ) =>
        request<Offer>({
            path: `/offer/${id}`,
            method: RequestMethod.PUT,
            params: {
                amount,
                loanTerm,
                loanRate,
                type,
                count,
            },
        })

    const closeOffer = (id: string) =>
        request({
            path: `/offer/${id}`,
            method: RequestMethod.DELETE,
        })

    const listOffers = (query: string, page?: number, perPage?: number) =>
        request<Paginated<Offer>>({
            path: `/offer`,
            method: RequestMethod.GET,
            params: {
                query,
                page,
                perPage,
            },
        })

    const listMyOffers = (query: string, page?: number, perPage?: number) =>
        request<Paginated<Offer>>({
            path: `/user/offers`,
            method: RequestMethod.GET,
            params: {
                query,
                page,
                perPage,
            },
        })

    const getSpecificOffer = (id: string) =>
        request<Offer>({
            path: `/offer/${id}`,
            method: RequestMethod.GET,
        })

    const getSpecificOrder = (id: string) =>
        request<Order>({
            path: `/order/${id}`,
            method: RequestMethod.GET,
        })

    const listUserOrders = (page?: number, perPage?: number) =>
        request<Paginated<Order>>({
            path: `/user/orders`,
            method: RequestMethod.GET,
            params: {
                page,
                perPage,
            },
        })

    const listOfferOrders = (id: string, page?: number, perPage?: number) =>
        request<Paginated<Order>>({
            path: `/offer/${id}/orders`,
            method: RequestMethod.GET,
            params: {
                page,
                perPage,
            },
        })

    const createOrder = (offer_id: string) =>
        request<Order>({
            path: `/order`,
            method: RequestMethod.POST,
            params: {
                offer_id,
            },
        })

    const approveOrder = (id: string) =>
        request<Order>({
            path: `/order/${id}/approve`,
            method: RequestMethod.GET,
        })

    const rejectOrder = (id: string) =>
        request<Order>({
            path: `/order/${id}/reject`,
            method: RequestMethod.GET,
        })

    const signUserAgreement = (type: TabIndex) =>
        request({
            path: `/user/sign`,
            method: RequestMethod.POST,
            params: {
                type,
            },
        })

    const isMsiDataExists = () =>
        request({
            path: `/msi/check`,
            method: RequestMethod.GET,
        })

    const getMsiURL = () =>
        request({
            path: `/msi/url`,
            method: RequestMethod.GET,
        })

    const getMsiData = () =>
        request({
            path: `/msi/data`,
            method: RequestMethod.GET,
        })

    const sendSms = (document_id: string) =>
        request({
            path: `/sms/send`,
            method: RequestMethod.POST,
            params: {
                document_id,
            },
        })

    const signWithSms = (document_id: string, code: string) =>
        request({
            path: `/sms/sign`,
            method: RequestMethod.POST,
            params: {
                document_id,
                code,
            },
        })

    const bepaidAuthParams = () =>
        request({
            path: `/bepaid/auth`,
            method: RequestMethod.GET,
        })

    const listDocuments = () =>
        request<Document[]>({
            path: `/documents`,
            method: RequestMethod.GET,
        })

    const getSpecificDocument = (id: string) =>
        request<Document>({
            path: `/documents/${id}`,
            method: RequestMethod.GET,
        })

    const regStatus = () =>
        request<RegStatus>({
            path: `/auth/status`,
            method: RequestMethod.GET,
        })

    const offerVars = () =>
        request<OfferVars>({
            path: `/offer-vars`,
            method: RequestMethod.GET,
        })

    const getQuestionnaireData = () =>
        request<QuestionnaireData>({
            path: `/questionnaire`,
            method: RequestMethod.GET,
        })

    const postQuestionnaireData = (data: QuestionnaireData) =>
        request<QuestionnaireData>({
            path: `/questionnaire`,
            method: RequestMethod.POST,
            params: data,
        })

    const questionnaireExists = () =>
        request({
            path: `/questionnaire/exists`,
            method: RequestMethod.GET,
        })

    const payForOrder = (orderId: string) =>
        request({
            path: `/order/${orderId}/pay`,
            method: RequestMethod.GET,
        })

    const setDefaultCard = (cardId: string) =>
        request<User>({
            path: `/user/card`,
            method: RequestMethod.POST,
            params: {
                defaultCard: cardId,
            },
        })

    return {
        logIn,
        getCurrentUser,
        registerUser,
        listCards,
        deleteCard,
        listOffers,
        createOffer,
        updateOffer,
        closeOffer,
        getSpecificOffer,
        getSpecificOrder,
        listMyOffers,
        listUserOrders,
        listOfferOrders,
        createOrder,
        approveOrder,
        rejectOrder,
        getUserAccesses,
        signUserAgreement,
        isMsiDataExists,
        getMsiURL,
        getMsiData,
        sendSms,
        bepaidAuthParams,
        listDocuments,
        getSpecificDocument,
        signWithSms,
        regStatus,
        offerVars,
        getQuestionnaireData,
        postQuestionnaireData,
        questionnaireExists,
        payForOrder,
        setDefaultCard,
    }
}

export default {
    create,
}
