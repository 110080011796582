import { SelectProps } from 'antd'
import _ from 'lodash'
import { countries } from '../../locales'
import { firstLetterUpper } from '../../utils/helpers'

export const countriesOptions: SelectProps['options'] = _.sortBy(
    countries.data.map((x) => ({
        value: Number.parseInt(x.code),
        label: firstLetterUpper(x.shortname),
    })),
    ['label']
)

export const employmentTypeOptions: SelectProps['options'] = _.sortBy(
    [
        {
            value: 1,
            label: 'В декретном отпуске',
        },
        {
            value: 2,
            label: 'Временно не работаю',
        },
        {
            value: 3,
            label: 'Иной источник дохода',
        },
        {
            value: 4,
            label: 'Пенсионер',
        },
        {
            value: 5,
            label: 'Предприниматель',
        },
        {
            value: 6,
            label: 'Работаю официально',
        },
        {
            value: 7,
            label: 'Работающий пенсионер',
        },
        {
            value: 8,
            label: 'Самозанятый',
        },
        {
            value: 9,
            label: 'Студент',
        },
    ],
    ['value']
)

export const activityFieldOptions: SelectProps['options'] = _.sortBy(
    [
        {
            value: 1,
            label: 'Банковское дело / финансы',
        },
        {
            value: 2,
            label: 'Вооружённые силы / правоохр. органы',
        },
        {
            value: 3,
            label: 'Государственное управление',
        },
        {
            value: 4,
            label: 'Здравоохранение / фармация',
        },
        {
            value: 5,
            label: 'IT',
        },
        {
            value: 6,
            label: 'Коммунальное хозяйство',
        },
        {
            value: 7,
            label: 'Культура',
        },
        {
            value: 8,
            label: 'Наука и образование',
        },
        {
            value: 9,
            label: 'Промышленность',
        },
        {
            value: 10,
            label: 'Развлечения',
        },
        {
            value: 11,
            label: 'Сельское хозяйство',
        },
        {
            value: 12,
            label: 'Строительство',
        },
        {
            value: 13,
            label: 'Торговля',
        },
        {
            value: 14,
            label: 'Услуги',
        },
    ],
    ['value']
)

export const educationOptions: SelectProps['options'] = _.sortBy(
    [
        {
            value: 1,
            label: 'Высшее образование',
        },
        {
            value: 2,
            label: 'Два и более высших образования',
        },
        {
            value: 3,
            label: 'МВА/ученая степень',
        },
        {
            value: 3,
            label: 'Неоконченное среднее',
        },
        {
            value: 4,
            label: 'Среднее',
        },
        {
            value: 5,
            label: 'Среднее специальное',
        },
    ],
    ['value']
)

export const maritalStatusOptions: SelectProps['options'] = _.sortBy(
    [
        {
            value: 1,
            label: 'Холост/не замужем',
        },
        {
            value: 2,
            label: 'Вдовец/вдова',
        },
        {
            value: 3,
            label: 'В разводе',
        },
        {
            value: 4,
            label: 'Гражданский брак',
        },
        {
            value: 5,
            label: 'Женат/замужем',
        },
        {
            value: 6,
            label: 'Холост/не замужем',
        },
    ],
    ['value']
)

export const housingConditionOptions: SelectProps['options'] = _.sortBy(
    [
        {
            value: 1,
            label: 'Арендуемая квартира/дом',
        },
        {
            value: 2,
            label: 'Общежитие',
        },
        {
            value: 3,
            label: 'Служебное жилье',
        },
        {
            value: 4,
            label: 'Собственный дом/квартира',
        },
        {
            value: 5,
            label: 'Совместно с родственниками',
        },
        {
            value: 6,
            label: 'Иное',
        },
    ],
    ['value']
)

export const minorChildrenOptions: SelectProps['options'] = _.sortBy(
    [
        {
            value: 1,
            label: '1 ребенок',
        },
        {
            value: 2,
            label: '2 ребенка',
        },
        {
            value: 3,
            label: '3 и более детей',
        },
        {
            value: 4,
            label: 'Нет детей',
        },
    ],
    ['value']
)
