import { all, put, StrictEffect, takeLeading, call } from 'redux-saga/effects'
import {
    Paginated,
    SagaGenerator,
    WithCallback,
    WithLocalize,
    WithObjectId,
} from '../../../@types/common'
import { ActionCreatorWithPayload, PayloadAction } from '@reduxjs/toolkit'
import { Api } from '../index'
import { ApiResponse } from 'apisauce'
import { throwNotification } from '../../../components/Notification'
import { NoticeTypes } from '../../../@types/notifications'
import moment from 'moment'
import { OrdersActions } from '../../reducers/orders'
import { Order } from '../../reducers/orders/types'

export function* listOrders(
    api: Api,
    action: PayloadAction<Partial<Paginated<{}>>>
) {
    const { page, perPage } = action.payload

    yield put(OrdersActions.setOrdersLoading(true))

    let result: ApiResponse<Paginated<Order>> = yield call(
        api.listUserOrders,
        page,
        perPage
    )

    if (result.status === 200 && result.data) {
        for (let order of result.data.data) {
            order.createdAt = moment(order.createdAt)
            order.deletedAt = order.deletedAt
                ? moment(order.deletedAt)
                : undefined
            order.updatedAt = moment(order.updatedAt)
        }
        yield put(OrdersActions.setOrders(result.data))
    }

    yield put(OrdersActions.setOrdersLoading(false))
}

export function* listOfferOrders(
    api: Api,
    action: PayloadAction<WithObjectId<Partial<Paginated<{}>>>>
) {
    const { page, perPage, id } = action.payload

    yield put(OrdersActions.setOfferOrdersLoading(true))

    let result: ApiResponse<Paginated<Order>> = yield call(
        api.listOfferOrders,
        id,
        page,
        perPage
    )

    if (result.status === 200 && result.data) {
        for (let order of result.data.data) {
            order.createdAt = moment(order.createdAt)
            order.deletedAt = order.deletedAt
                ? moment(order.deletedAt)
                : undefined
            order.updatedAt = moment(order.updatedAt)
        }
        yield put(OrdersActions.setOfferOrders(result.data))
    }

    yield put(OrdersActions.setOfferOrdersLoading(false))
}

export function* createOrder(
    api: Api,
    action: PayloadAction<WithCallback<WithLocalize<WithObjectId<{}>>>>
) {
    const { id, localize, callback } = action.payload

    let result: ApiResponse<Order> = yield call(api.createOrder, id)

    if (result.status === 200 && result.data) {
        throwNotification(NoticeTypes.Success, localize('orderCreated'))
        yield listOfferOrders(api, { type: '', payload: { id } })
        callback()
    }
}

export function* approveOrder(
    api: Api,
    action: PayloadAction<WithCallback<WithLocalize<WithObjectId<{}>>>>
) {
    const { id, localize, callback } = action.payload

    let result: ApiResponse<Notification> = yield call(api.approveOrder, id)

    if (result.status === 200) {
        throwNotification(NoticeTypes.Success, localize('orderAccepted'))
        callback()
    }
}

export function* payForOrder(
    api: Api,
    action: PayloadAction<WithCallback<WithLocalize<WithObjectId<{}>>>>
) {
    yield put(OrdersActions.setPaymentLoading(true))
    const { id, localize, callback } = action.payload

    let result: ApiResponse<Notification> = yield call(api.payForOrder, id)

    if (result.status === 200) {
        throwNotification(NoticeTypes.Success, localize('orderPaid'))
        callback()
    }
    yield put(OrdersActions.setPaymentLoading(false))
}

export function* rejectOrder(
    api: Api,
    action: PayloadAction<WithCallback<WithLocalize<WithObjectId<{}>>>>
) {
    const { id, localize, callback } = action.payload

    let result: ApiResponse<Notification> = yield call(api.rejectOrder, id)

    if (result.status === 200) {
        throwNotification(NoticeTypes.Success, localize('orderRejected'))
        callback()
    }
}

export function* getSpecificOrder(
    api: Api,
    action: PayloadAction<WithCallback<WithObjectId<{}>>>
) {
    const { id, callback } = action.payload

    yield put(OrdersActions.setSpecificOrderLoading(true))
    yield put(OrdersActions.setSpecificOrder(undefined))
    let result: ApiResponse<Order> = yield call(api.getSpecificOrder, id)

    if (result.status === 200 && result.data) {
        const order = result.data
        order.createdAt = moment(order.createdAt)
        order.updatedAt = moment(order.updatedAt)
        order.deletedAt = order.deletedAt ? moment(order.deletedAt) : undefined
        yield put(OrdersActions.setSpecificOrder(result.data))
    } else {
        callback()
    }

    yield put(OrdersActions.setSpecificOrderLoading(true))
}

export default function* groupsSagas(api: Api): SagaGenerator<StrictEffect> {
    yield all([
        yield takeLeading(
            OrdersActions.requestOrders as ActionCreatorWithPayload<
                Partial<Paginated<{}>>
            >,
            listOrders,
            api
        ),
        yield takeLeading(
            OrdersActions.requestOfferOrders as ActionCreatorWithPayload<
                WithObjectId<Partial<Paginated<{}>>>
            >,
            listOfferOrders,
            api
        ),
        yield takeLeading(
            OrdersActions.createOrder as ActionCreatorWithPayload<
                WithCallback<WithLocalize<WithObjectId<{}>>>
            >,
            createOrder,
            api
        ),
        yield takeLeading(
            OrdersActions.rejectOrder as ActionCreatorWithPayload<
                WithCallback<WithLocalize<WithObjectId<{}>>>
            >,
            rejectOrder,
            api
        ),
        yield takeLeading(
            OrdersActions.approveOrder as ActionCreatorWithPayload<
                WithCallback<WithLocalize<WithObjectId<{}>>>
            >,
            approveOrder,
            api
        ),
        yield takeLeading(
            OrdersActions.payForOrder as ActionCreatorWithPayload<
                WithCallback<WithLocalize<WithObjectId<{}>>>
            >,
            payForOrder,
            api
        ),
        yield takeLeading(
            OrdersActions.requestSpecificOrder as ActionCreatorWithPayload<
                WithCallback<WithObjectId<{}>>
            >,
            getSpecificOrder,
            api
        ),
    ])
}
