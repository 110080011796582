import { all, put, StrictEffect, takeLeading, call } from 'redux-saga/effects'
import {
    Paginated,
    SagaGenerator,
    WithLocalize,
    WithObjectId,
} from '../../../@types/common'
import { ActionCreatorWithPayload, PayloadAction } from '@reduxjs/toolkit'
import { Api } from '../index'
import { ApiResponse } from 'apisauce'
import { throwNotification } from '../../../components/Notification'
import { NoticeTypes } from '../../../@types/notifications'
import { Card } from '../../reducers/cards/types'
import { CardsActions } from '../../reducers/cards'
import { User } from '../../reducers/home/types'
import { HomeActions } from '../../reducers/home'

export function* listCards(
    api: Api,
    action: PayloadAction<Partial<Paginated<{}>>>
) {
    const { page, perPage } = action.payload

    yield put(CardsActions.setCardsLoading(true))

    let result: ApiResponse<Paginated<Card>> = yield call(
        api.listCards,
        page,
        perPage
    )

    if (result.status === 200 && result.data) {
        yield put(CardsActions.setCards(result.data))
    }

    yield put(CardsActions.setCardsLoading(false))
}

export function* deleteCard(
    api: Api,
    action: PayloadAction<WithLocalize<WithObjectId<{}>>>
) {
    const { id, localize } = action.payload

    let result: ApiResponse<null> = yield call(api.deleteCard, id)

    if (result.status === 200) {
        throwNotification(NoticeTypes.Success, localize('cardDeleted'))
        yield listCards(api, { type: '', payload: {} })
    }
}

export function* setDefaultCard(
    api: Api,
    action: PayloadAction<WithObjectId<{}>>
) {
    const { id } = action.payload

    let result: ApiResponse<User> = yield call(api.setDefaultCard, id)

    if (result.status === 200) {
        yield put(HomeActions.setUser(result.data))
    }
}

export default function* groupsSagas(api: Api): SagaGenerator<StrictEffect> {
    yield all([
        yield takeLeading(
            CardsActions.deleteCard as ActionCreatorWithPayload<
                WithLocalize<WithObjectId<{}>>
            >,
            deleteCard,
            api
        ),
        yield takeLeading(
            CardsActions.requestCards as ActionCreatorWithPayload<
                Partial<Paginated<{}>>
            >,
            listCards,
            api
        ),
        yield takeLeading(
            CardsActions.setDefaultCard as ActionCreatorWithPayload<
                WithObjectId<{}>
            >,
            setDefaultCard,
            api
        ),
    ])
}
