export type ScreenSize = 'xs' | 'sm' | 'md' | 'lg';
export type ScreenSizesType = ScreenSize[];

export const screenSizes: ScreenSizesType = ['xs', 'sm', 'md', 'lg'];

export const screenMinWidths = {
    xs: 0,
    sm: 768,
    md: 992,
    lg: 1200
};

export const screenMaxWidths = {
    xs: 767,
    sm: 991,
    md: 1199,
    lg: undefined
};
