import React, { FC, useEffect, useMemo, useRef } from 'react'
import { useLocalizationContext, useMobile } from '../../utils/contexts'
import classNames from 'classnames'
import styles from './CardsPage.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { CardsActions, CardsSelectors } from '../../redux/reducers/cards'
import { Checkbox, Space, TableProps } from 'antd'
import { CreditCardOutlined, DeleteOutlined } from '@ant-design/icons'
import {
    CustomPagination,
    PageTitle,
    PaymentComponent,
    ResponsiveTable,
} from '../../components'
import { useLocation } from 'react-router-dom'
import { Card } from '../../redux/reducers/cards/types'
import { HomeSelectors } from '../../redux/reducers/home'

export const CardsPage: FC = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const { localize } = useLocalizationContext()
    const isMobile = useMobile()
    const cards = useSelector(CardsSelectors.getCards)
    const ref = useRef<HTMLDivElement>(null)
    const user = useSelector(HomeSelectors.getUser)

    useEffect(() => {
        dispatch(CardsActions.requestCards({}))
    }, [location.pathname])

    const columns: TableProps<Card>['columns'] = useMemo(
        () => [
            {
                title: localize('cardNumber'),
                dataIndex: 'last_4',
                key: 'last_4',
                render: (value) => `**** **** **** ${value}`,
            },
            {
                title: localize('cardExpiry'),
                dataIndex: 'exp_month',
                key: 'exp_month',
                render: (_, obj) => `${obj.exp_month}/${obj.exp_year}`,
            },
            {
                title: localize('brand'),
                dataIndex: 'brand',
                key: 'brand',
            },
            {
                title: 'По умолчанию',
                key: 'defaultCard',
                width: '150px',
                render: (_, record) => (
                    <Space className={styles.actions} size='middle'>
                        <Checkbox
                            checked={user?.defaultCard === record.id}
                            onChange={() => {
                                if (user?.defaultCard !== record.id) {
                                    dispatch(
                                        CardsActions.setDefaultCard({
                                            id: record.id,
                                        })
                                    )
                                }
                            }}
                        />
                    </Space>
                ),
            },
            {
                title: localize('actions'),
                key: 'action',
                render: (_, record) => (
                    <Space className={styles.actions} size='middle'>
                        <DeleteOutlined
                            onClick={() =>
                                dispatch(
                                    CardsActions.deleteCard({
                                        id: record.id!,
                                        localize,
                                    })
                                )
                            }
                        />
                    </Space>
                ),
            },
        ],
        [localize]
    )

    return (
        <div
            className={classNames(styles.pageContainer, {
                [styles.pageContainerMobile]: isMobile,
            })}
            ref={ref}
        >
            <PageTitle
                title={localize('cards')}
                icon={<CreditCardOutlined />}
                button={<PaymentComponent />}
            />
            {cards && (
                <ResponsiveTable
                    rowKey={'id'}
                    columns={columns}
                    dataSource={cards.data}
                    pagination={false}
                />
            )}
            {cards && (
                <CustomPagination
                    parentRef={ref}
                    showSizeChanger
                    onChange={(page, perPage) =>
                        dispatch(CardsActions.requestCards({ page, perPage }))
                    }
                    onShowSizeChange={(page, perPage) => {
                        dispatch(CardsActions.requestCards({ page, perPage }))
                    }}
                    defaultCurrent={cards.page}
                    total={cards.total}
                />
            )}
        </div>
    )
}
