import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CardsState, Card, CardRequestPayload } from './types'
import {
    Paginated,
    WithCallback,
    WithLocalize,
    WithObjectId,
} from '../../../@types/common'

export const initialState: CardsState = {
    cards: undefined,
    cardsLoading: false,
}

const cardsSlice = createSlice({
    name: 'cards',
    initialState,
    reducers: {
        requestCards: (
            state,
            action: PayloadAction<Partial<Paginated<{}>>>
        ) => {},
        setDefaultCard: (state, action: PayloadAction<WithObjectId<{}>>) => {},
        createCard: (
            state,
            action: PayloadAction<
                WithLocalize<WithCallback<CardRequestPayload>>
            >
        ) => {},
        updateCard: (
            state,
            action: PayloadAction<
                WithLocalize<WithObjectId<WithCallback<CardRequestPayload>>>
            >
        ) => {},
        deleteCard: (
            state,
            action: PayloadAction<WithLocalize<WithObjectId<{}>>>
        ) => {},
        setCards: (state, action: PayloadAction<Paginated<Card>>) => {
            state.cards = action.payload
        },
        setCardsLoading: (state, action: PayloadAction<boolean>) => {
            state.cardsLoading = action.payload
        },
    },
})

export const CardsActions = cardsSlice.actions

interface CardsReducerState {
    cardsReducer: CardsState
}

export const CardsSelectors = {
    getCards: (state: CardsReducerState) => state.cardsReducer.cards,
    getCardsLoading: (state: CardsReducerState) =>
        state.cardsReducer.cardsLoading,
}

export default cardsSlice.reducer
