import React, { FC, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import styles from './WizardPage.module.scss'
import { useLocalizationContext, useMobile } from '../../utils/contexts'
import {
    BEPAID_SIGNED,
    KI_SIGNED,
    MSI_SIGNED,
    QUESTIONNAIRE_SIGNED,
} from '../../@types/accesses'
import { useDispatch, useSelector } from 'react-redux'
import { HomeActions, HomeSelectors } from '../../redux/reducers/home'
import { Button, Tabs, TabsProps } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { PageTitle } from '../../components'
import { BePaid, KI, MSI, Questionnaire } from './Tabs'
import { useNavigate } from 'react-router-dom'
import { throwNotification } from '../../components/Notification'
import { NoticeTypes } from '../../@types/notifications'
import { Links } from '../../constants/routes'
import { MsiActions, MsiSelectors } from '../../redux/reducers/msi'

export enum TabIndex {
    MSI = 'msi',
    BEPAID = 'bepaid',
    KI = 'ki',
    QUESTIONNAIRE = 'questionnaire',
}

export const WizardPage: FC = () => {
    const dispatch = useDispatch()
    const { localize } = useLocalizationContext()
    const navigate = useNavigate()
    const isMobile = useMobile()
    const user = useSelector(HomeSelectors.getUser)
    const accesses = useSelector(HomeSelectors.getAccesses)
    const [activeKey, setActiveKey] = useState<TabIndex>()
    const msiUrl = useSelector(MsiSelectors.getMsiUrl)

    useEffect(() => {
        if (activeKey) {
            dispatch(MsiActions.requestMsiURL())
        }
    }, [activeKey])

    const onNext = (nextKey?: TabIndex) => () => {
        if (activeKey === TabIndex.MSI) {
            window.location.href = msiUrl!
        } else if (activeKey === TabIndex.QUESTIONNAIRE) {
            navigate(Links.Questionnaire)
        } else {
            dispatch(
                HomeActions.createToken({
                    type: activeKey!,
                    callback: () => {
                        dispatch(HomeActions.requestUserAccesses())
                        throwNotification(
                            NoticeTypes.Success,
                            'Тут будет интеграция с провайдером!'
                        )
                        if (nextKey) {
                            setActiveKey(nextKey)
                        } else {
                            navigate(Links.Offers)
                        }
                    },
                })
            )
        }
    }

    const items: TabsProps['items'] = useMemo(
        () => [
            ...(accesses && !accesses[MSI_SIGNED]
                ? [
                      {
                          key: TabIndex.MSI,
                          label: localize('wizzardMSITitle'),
                          children: <MSI next={onNext(TabIndex.BEPAID)} />,
                      },
                  ]
                : []),
            ...(accesses && !accesses[BEPAID_SIGNED]
                ? [
                      {
                          key: TabIndex.BEPAID,
                          label: localize('wizzardBePaidTitle'),
                          children: <BePaid next={onNext(TabIndex.KI)} />,
                      },
                  ]
                : []),
            ...(accesses && !accesses[KI_SIGNED]
                ? [
                      {
                          key: TabIndex.KI,
                          label: localize('wizzardKITitle'),
                          children: (
                              <KI next={onNext(TabIndex.QUESTIONNAIRE)} />
                          ),
                      },
                  ]
                : []),
            ...(accesses && !accesses[QUESTIONNAIRE_SIGNED]
                ? [
                      {
                          key: TabIndex.QUESTIONNAIRE,
                          label: localize('wizzardQuestionnaireTitle'),
                          children: <Questionnaire next={onNext()} />,
                      },
                  ]
                : []),
        ],
        [accesses, user, localize, onNext]
    )

    useEffect(() => {
        setActiveKey((items[0]?.key as any) || undefined)
    }, [items])

    return (
        <div
            className={classNames(styles.pageContainer, {
                [styles.pageContainerMobile]: isMobile,
            })}
        >
            <PageTitle
                title={localize('verification')}
                icon={<InfoCircleOutlined />}
            />
            <Tabs activeKey={activeKey} items={items} />
            {items.length === 0 && (
                <>
                    <div>{localize('verificationComplete')}</div>
                    <Button
                        size={'middle'}
                        type={'primary'}
                        style={{ width: '200px' }}
                        onClick={() => navigate(Links.MsiData)}
                    >
                        {localize('goToProfile')}
                    </Button>
                </>
            )}
        </div>
    )
}
