import { all, call, put, StrictEffect, takeLeading } from 'redux-saga/effects'
import { SagaGenerator, WithCallback } from '../../../@types/common'
import { Api } from '../index'
import { ApiResponse } from 'apisauce'
import { QuestionnaireActions } from '../../reducers/questionnaire'
import { QuestionnaireData } from '../../reducers/questionnaire/types'
import { ActionCreatorWithPayload, PayloadAction } from '@reduxjs/toolkit'
import { throwNotification } from '../../../components/Notification'
import { NoticeTypes } from '../../../@types/notifications'

export function* requestData(api: Api) {
    yield put(QuestionnaireActions.setDataLoading(true))

    let result: ApiResponse<QuestionnaireData> = yield call(
        api.getQuestionnaireData
    )

    if (result.status === 200 && result.data) {
        yield put(QuestionnaireActions.setData(result.data))
    }

    yield put(QuestionnaireActions.setDataLoading(false))
}

export function* requestExists(api: Api) {
    let result: ApiResponse<{ exists: boolean }> = yield call(
        api.questionnaireExists
    )

    if (result.status === 200 && result.data) {
        yield put(QuestionnaireActions.setExists(result.data.exists))
    }
}

export function* updateData(
    api: Api,
    action: PayloadAction<WithCallback<{ data: QuestionnaireData }>>
) {
    const { callback, data } = action.payload
    yield put(QuestionnaireActions.setDataLoading(true))

    let result: ApiResponse<QuestionnaireData> = yield call(
        api.postQuestionnaireData,
        data
    )

    if (result.status === 200 && result.data) {
        yield put(QuestionnaireActions.setData(result.data))
        throwNotification(NoticeTypes.Success, 'Анкета сохранена!')
        callback()
    }

    yield put(QuestionnaireActions.setDataLoading(false))
}

export default function* groupsSagas(api: Api): SagaGenerator<StrictEffect> {
    yield all([
        yield takeLeading(QuestionnaireActions.requestData, requestData, api),
        yield takeLeading(
            QuestionnaireActions.requestExists,
            requestExists,
            api
        ),
        yield takeLeading(
            QuestionnaireActions.updateData as ActionCreatorWithPayload<
                WithCallback<{ data: QuestionnaireData }>
            >,
            updateData,
            api
        ),
    ])
}
