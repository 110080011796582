import React, { FC } from 'react'
import { Button } from 'antd'

export type KIProps = {
    next: Function
}

export const KI: FC<KIProps> = ({ next }) => {
    return (
        <div>
            <Button onClick={() => next()}>Заполнить КИ</Button>
        </div>
    )
}
