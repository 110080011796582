import { Moment } from 'moment'
import { User } from '../home/types'
import { Paginated } from '../../../@types/common'
import { Order } from '../orders/types'

export type OffersState = {
    offers?: Paginated<Offer>
    offersLoading: boolean
    specificOffer?: Offer
    specificOfferLoading: boolean
    offerVars?: OfferVars
}

export type OfferVars = {
    serviceReward: number
    delayPenalty: number
}

export enum OfferType {
    Bid,
    Ask,
}

export enum OfferState {
    NotSigned,
    Opened,
    Closed,
}

export type Offer = {
    createdAt: Moment
    deletedAt?: Moment
    count: number
    id: string | null
    updatedAt: Moment
    owner: User
    amount: number
    loanTerm: number
    loanRate: number
    type: OfferType
    state: OfferState
    orders: Order[]
}

export type OfferRequestPayload = {
    amount: number
    loanTerm: number
    loanRate: number
    type: OfferType
    count: number
}
