import React, { FC } from 'react'
import { Typography } from 'antd'
import styles from './PageTitle.module.scss'

export type PageTitleProps = {
    title: string
    icon: React.ReactElement
    button?: React.ReactElement
}

export const PageTitle: FC<PageTitleProps> = ({ title, icon, button }) => {
    return (
        <div className={styles.wrapper}>
            <Typography.Title className={styles.title} level={2}>
                {icon}
                {title}
            </Typography.Title>
            {button && <div className={styles.button}>{button}</div>}
        </div>
    )
}
