declare global {
    interface Window {
        env: any
    }

    class BeGateway {
        constructor(args: any)

        createWidget(): any
    }
}

type EnvType = {
    REACT_APP_CORE_API_URL: string
    REACT_APP_RETURN_GUARANTEE: string
    REACT_APP_SUPPORT_EMAIL: string
    REACT_APP_LOAN_AGREEMENT: string
}

export const env: EnvType = { ...process.env, ...window.env }
