import React, { useContext } from 'react'
import { ScreenSize } from '../../components'

export const ResponsiveContext = React.createContext<ScreenSize>('lg')

export const useResponsiveContext = () => useContext(ResponsiveContext)

export const useMobile = () => {
    const size = useContext(ResponsiveContext)

    return size === 'xs'
}
