import React, { FC, PropsWithChildren } from 'react'
import { ResponsiveContext } from '../../utils/contexts'
import { Responsive } from './Responsive'

export const ResponsiveContainer: FC<PropsWithChildren> = ({ children }) => {
    return (
        <Responsive>
            {(size) => {
                return (
                    <ResponsiveContext.Provider value={size}>
                        {children}
                    </ResponsiveContext.Provider>
                )
            }}
        </Responsive>
    )
}
