import { Moment } from 'moment'
import { Dictionary, Language } from '../../../@types/common'
import { TabIndex } from '../../../pages/WizardPage'

export type HomeState = {
    isLoggedIn: boolean
    locale: Language
    user?: User
    accesses?: Dictionary<boolean>
    isInitialized: boolean
    bepaidAuthParams?: Object
    regStatus?: RegStatus
}

export type RegStatus = {
    regEnabled: boolean
}

export type LoginRequestPayload = {
    login: string
    password: string
}

export type RegisterRequestPayload = {
    login: string
    password: string
    type: UserType
    number?: string
    email?: string
}

export type AuthResponse = {
    token: string
    user: User
}

export enum UserType {
    Individual,
    LegalEntity,
}

export type User = {
    createdAt: Moment
    deletedAt?: Moment
    email: string
    id: string | null
    login: string
    phone: string | null
    type: UserType
    updatedAt: Moment
    defaultCard?: string
}

export type TokenDto = {
    type: TabIndex
}
