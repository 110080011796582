import { all, put, StrictEffect, takeLeading, call } from 'redux-saga/effects'
import { HomeActions } from '../../reducers/home'
import {
    Dictionary,
    SagaGenerator,
    WithCallback,
    WithLocalize,
} from '../../../@types/common'
import { ActionCreatorWithPayload, PayloadAction } from '@reduxjs/toolkit'
import { Api } from '../index'
import {
    AuthResponse,
    LoginRequestPayload,
    RegisterRequestPayload,
    RegStatus,
    TokenDto,
} from '../../reducers/home/types'
import { ApiResponse } from 'apisauce'
import _ from 'lodash'
import { TOKEN_KEY } from '../../../constants'
import moment from 'moment'
import { throwNotification } from '../../../components/Notification'
import { NoticeTypes } from '../../../@types/notifications'

export function* requestUserAccesses(api: Api) {
    yield call(api.isMsiDataExists)

    let result: ApiResponse<Dictionary<boolean>> = yield call(
        api.getUserAccesses
    )

    yield put(HomeActions.setAccesses(result.data))
}

export function* requestBepaidAuthParams(api: Api) {
    let result: ApiResponse<any> = yield call(api.bepaidAuthParams)

    if (result.status === 200) {
        yield put(HomeActions.setBepaidAuthParams(result.data))
    }
}

export function* requestRegStatus(api: Api) {
    let result: ApiResponse<RegStatus> = yield call(api.regStatus)

    if (result.status === 200) {
        yield put(HomeActions.setRegStatus(result.data))
    }
}

function* handleAuthResponse(result: ApiResponse<AuthResponse>, api: Api) {
    if (result.status === 200 && result.data) {
        const user = _.cloneDeep(result.data.user)
        localStorage.setItem(TOKEN_KEY, result.data.token)
        user.createdAt = moment(user.createdAt)
        user.deletedAt = user.deletedAt ? moment(user.deletedAt) : undefined
        user.updatedAt = moment(user.updatedAt)

        yield requestUserAccesses(api)

        yield put(HomeActions.setUser(user))
        yield put(HomeActions.setIsLoggedIn(true))
    }
}

export function* requestSignIn(
    api: Api,
    action: PayloadAction<WithLocalize<LoginRequestPayload>>
) {
    const { login, password, localize } = action.payload

    let result: ApiResponse<AuthResponse> = yield call(
        api.logIn,
        login,
        password
    )

    if (result.status === 200) {
        throwNotification(NoticeTypes.Success, localize('loginSuccess'))
    }

    yield handleAuthResponse(result, api)
}

export function* requestGetCurrentUser(api: Api) {
    try {
        let result: ApiResponse<AuthResponse> = yield call(api.getCurrentUser)

        yield handleAuthResponse(result, api)
    } catch {}

    yield put(HomeActions.setIsInitialized(true))
}

export function* requestRegisterUser(
    api: Api,
    action: PayloadAction<WithLocalize<RegisterRequestPayload>>
) {
    const { login, password, type, email, number, localize } = action.payload

    let result: ApiResponse<AuthResponse> = yield call(
        api.registerUser,
        login,
        password,
        type,
        email,
        number
    )

    if (result.status === 200) {
        throwNotification(NoticeTypes.Success, localize('registrationSuccess'))
    }

    yield handleAuthResponse(result, api)
}

export function* createToken(
    api: Api,
    action: PayloadAction<WithCallback<TokenDto>>
) {
    const { type, callback } = action.payload

    const result: ApiResponse<TokenDto> = yield call(
        api.signUserAgreement,
        type
    )

    if (result.status === 200) {
        callback()
    }
}

export default function* groupsSagas(api: Api): SagaGenerator<StrictEffect> {
    yield all([
        yield takeLeading(
            HomeActions.requestSignIn as ActionCreatorWithPayload<
                WithLocalize<LoginRequestPayload>
            >,
            requestSignIn,
            api
        ),
        yield takeLeading(
            HomeActions.requestUserAccesses,
            requestUserAccesses,
            api
        ),
        yield takeLeading(
            HomeActions.requestBepaidAuthParams,
            requestBepaidAuthParams,
            api
        ),
        yield takeLeading(
            HomeActions.requestRegisterUser as ActionCreatorWithPayload<
                WithLocalize<RegisterRequestPayload>
            >,
            requestRegisterUser,
            api
        ),
        yield takeLeading(
            HomeActions.requestGetCurrentUser,
            requestGetCurrentUser,
            api
        ),
        yield takeLeading(
            HomeActions.createToken as ActionCreatorWithPayload<
                WithCallback<TokenDto>
            >,
            createToken,
            api
        ),
        yield takeLeading(HomeActions.requestRegStatus, requestRegStatus, api),
    ])
}
