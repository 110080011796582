import React, { useContext } from 'react'
import LocalizedStrings, { LocalizedStringsMethods } from 'react-localization'
import { Language, Translations, Dictionary } from '../../@types/common'
import moment from 'moment'

export type FormatLocalize = (key: string, props: Dictionary) => string

type FormatLocalizePluralizeProps = {
    key: string
    params: Dictionary
    count: number
    two: string
    five: string
}

type FormatDate = (
    date: moment.MomentInput,
    format?: string | undefined
) => string | null

export interface ILocalizationContext {
    locale: Language
    strings: LocalizedStringsMethods
    addTranslation: (value: Translations) => void
    localize: (key: string) => string
    format: FormatLocalize
    formatLocalize: FormatLocalize
    pluralize: (count: number, one: string, two: string, five: string) => string
    formatDate: FormatDate
    formatUTCDate: FormatDate
    formatLocalizePluralize: (props: FormatLocalizePluralizeProps) => string
}

export const LocalizationContext = React.createContext<ILocalizationContext>({
    locale: Language.English,
    strings: new LocalizedStrings({ [Language.English]: {} }),
    addTranslation: (): any => {},
    localize: (): any => {},
    format: (): any => {},
    formatLocalize: (): any => {},
    pluralize: (): any => {},
    formatDate: (): any => {},
    formatUTCDate: (): any => {},
    formatLocalizePluralize: (): any => {},
})

export const useLocalizationContext = () => useContext(LocalizationContext)
