import React, { FC, useEffect, useMemo, useState } from 'react'
import { useMobile } from '../../utils/contexts'
import classNames from 'classnames'
import styles from './QuestionnairePage.module.scss'
import { FileOutlined } from '@ant-design/icons'
import { PageTitle } from '../../components'
import { Input, Space, Typography, Radio, Checkbox, Select, Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import {
    QuestionnaireActions,
    QuestionnaireSelectors,
} from '../../redux/reducers/questionnaire'
import { QuestionnaireData } from '../../redux/reducers/questionnaire/types'
import { HomeActions } from '../../redux/reducers/home'
import {
    activityFieldOptions,
    countriesOptions,
    educationOptions,
    employmentTypeOptions,
    housingConditionOptions,
    maritalStatusOptions,
    minorChildrenOptions,
} from './options'
const { Title, Text } = Typography

export const QuestionnairePage: FC = () => {
    const dispatch = useDispatch()
    const isMobile = useMobile()
    const [state, setState] = useState<QuestionnaireData>()
    const [isLoaded, setIsLoaded] = useState(false)

    const data = useSelector(QuestionnaireSelectors.getData)
    const exists = useSelector(QuestionnaireSelectors.getExists)

    useEffect(() => {
        if (data) {
            setState(data)
            setIsLoaded(true)
        }
    }, [data])

    useEffect(() => {
        dispatch(QuestionnaireActions.requestExists())
        console.log(123)
    }, [])

    useEffect(() => {
        if (exists !== undefined) {
            if (exists) {
                dispatch(QuestionnaireActions.requestData())
            } else {
                setIsLoaded(true)
                setState({
                    incomeSource: [],
                })
            }
        }
    }, [exists])

    useEffect(() => {
        if (state && !state?.incomeSource.includes(6)) {
            setState((state) => ({
                ...state!,
                incomeSourceCustom: '',
            }))
        }
    }, [state?.incomeSource])

    useEffect(() => {
        if (state && !state?.isWorking) {
            setState((state) => ({
                ...state!,
                jobPosition: '',
                organisation: '',
            }))
        }
    }, [state?.isWorking])

    const isValid = useMemo(
        () =>
            state &&
            state.isWorking !== undefined &&
            (state.isWorking
                ? state.organisation &&
                  state.organisation.length > 0 &&
                  state.jobPosition &&
                  state.jobPosition.length > 0
                : true) &&
            state.isPublicOfficial !== undefined &&
            state.incomeSource.length > 0 &&
            (state.incomeSource.includes(6)
                ? state.incomeSourceCustom &&
                  state.incomeSourceCustom.length > 0
                : true) &&
            state.employmentType !== undefined &&
            state.activityField !== undefined &&
            state.education !== undefined &&
            state.maritalStatus !== undefined &&
            state.housingConditions !== undefined &&
            state.minorChildren !== undefined,
        [state]
    )

    const saveHandler = () => {
        if (isValid) {
            dispatch(
                QuestionnaireActions.updateData({
                    data: state!,
                    callback: () => {
                        dispatch(HomeActions.requestUserAccesses())
                    },
                })
            )
        }
    }

    return (
        <div
            className={classNames(styles.pageContainer, {
                [styles.pageContainerMobile]: isMobile,
            })}
        >
            <PageTitle title={'Анкета'} icon={<FileOutlined />} />
            {isLoaded && (
                <>
                    <Space direction={'vertical'} style={{ width: '100%' }}>
                        <Title level={3}>Общие сведения</Title>
                        <Space
                            direction={'horizontal'}
                            style={{ width: '100%', alignItems: 'flex-start' }}
                        >
                            <Space
                                direction={'vertical'}
                                style={{ width: '100%' }}
                            >
                                <Text>Имеете ли официальное место работы?</Text>
                                <Radio.Group
                                    onChange={(e) =>
                                        setState((state) => ({
                                            ...state!,
                                            isWorking: e.target.value,
                                        }))
                                    }
                                    value={state?.isWorking}
                                >
                                    <Space direction='vertical'>
                                        <Radio value={true}>Да</Radio>
                                        <Radio value={false}>Нет</Radio>
                                    </Space>
                                </Radio.Group>
                                <Text>Наименование организации</Text>
                                <Input
                                    disabled={!state?.isWorking}
                                    value={state?.organisation}
                                    onChange={(e) =>
                                        setState((state) => ({
                                            ...state!,
                                            organisation: e.target.value,
                                        }))
                                    }
                                />
                                <Text>Должность</Text>
                                <Input
                                    disabled={!state?.isWorking}
                                    value={state?.jobPosition}
                                    onChange={(e) =>
                                        setState((state) => ({
                                            ...state!,
                                            jobPosition: e.target.value,
                                        }))
                                    }
                                />
                            </Space>
                            <Space
                                direction={'vertical'}
                                style={{ width: '100%' }}
                            >
                                <Text>
                                    Происхождение материального состояния,
                                    источники средств:
                                </Text>
                                <Checkbox.Group
                                    options={[
                                        {
                                            label: 'Бизнес-деятельность организации, прибыль',
                                            value: 1,
                                        },
                                        {
                                            label: 'Заработная плата',
                                            value: 2,
                                        },
                                        {
                                            label: 'Кредиты/займы',
                                            value: 3,
                                        },
                                        {
                                            label: 'Личные сбережения',
                                            value: 4,
                                        },
                                        {
                                            label: 'Проценты от сбережений (депозит) на банковском счете',
                                            value: 5,
                                        },
                                        {
                                            label: 'иное (указать подробно):',
                                            value: 6,
                                        },
                                    ]}
                                    onChange={(incomeSource) =>
                                        setState((state) => ({
                                            ...state!,
                                            incomeSource,
                                        }))
                                    }
                                    value={state?.incomeSource}
                                />
                                <Input
                                    disabled={!state?.incomeSource.includes(6)}
                                    value={state?.incomeSourceCustom}
                                    onChange={(e) =>
                                        setState((state) => ({
                                            ...state!,
                                            incomeSourceCustom: e.target.value,
                                        }))
                                    }
                                />
                            </Space>
                            <Space
                                direction={'vertical'}
                                style={{ width: '100%' }}
                            >
                                <Text>Страна налогового резидентства</Text>
                                <Select
                                    options={countriesOptions}
                                    value={state?.taxResidenceCountry}
                                    onChange={(value) =>
                                        setState((state) => ({
                                            ...state!,
                                            taxResidenceCountry: value,
                                        }))
                                    }
                                    style={{ width: '100%' }}
                                />
                                <Text>Второе гражданство</Text>
                                <Select
                                    options={countriesOptions}
                                    value={state?.secondCitizenship}
                                    onChange={(value) =>
                                        setState((state) => ({
                                            ...state!,
                                            secondCitizenship: value,
                                        }))
                                    }
                                    style={{ width: '100%' }}
                                />
                                <Text>
                                    Вид на жительство в иностранном государстве,
                                    с указанием государства (при наличии)
                                </Text>
                                <Select
                                    options={countriesOptions}
                                    value={state?.residentCard}
                                    onChange={(value) =>
                                        setState((state) => ({
                                            ...state!,
                                            residentCard: value,
                                        }))
                                    }
                                    style={{ width: '100%' }}
                                />
                            </Space>
                        </Space>
                        <Text>
                            Являетесь ли Вы иностранным публичным должностным
                            лицом, должностным лицом публичных международных
                            организаций, лицом, занимающим должность, включенную
                            в определяемый Президентом Республики Беларусь
                            перечень государственных должностей Республики
                            Беларусь, членом их семей и приближенных к ним лиц?
                        </Text>
                        <Radio.Group
                            onChange={(e) =>
                                setState((state) => ({
                                    ...state!,
                                    isPublicOfficial: e.target.value,
                                }))
                            }
                            value={state?.isPublicOfficial}
                        >
                            <Space direction='vertical'>
                                <Radio value={true}>Да</Radio>
                                <Radio value={false}>Нет</Radio>
                            </Space>
                        </Radio.Group>
                        <br />
                        <Title level={3}>Дополнительные сведения</Title>
                        <Space
                            direction={'horizontal'}
                            style={{ width: '100%', alignItems: 'flex-start' }}
                        >
                            <Space
                                direction={'vertical'}
                                style={{ width: '100%' }}
                            >
                                <Text>Вид занятости</Text>
                                <Select
                                    value={state?.employmentType}
                                    onChange={(value) =>
                                        setState((state) => ({
                                            ...state!,
                                            employmentType: value,
                                        }))
                                    }
                                    options={employmentTypeOptions}
                                    style={{ width: '100%' }}
                                />
                                <Text>Образование</Text>
                                <Select
                                    value={state?.education}
                                    onChange={(value) =>
                                        setState((state) => ({
                                            ...state!,
                                            education: value,
                                        }))
                                    }
                                    options={educationOptions}
                                    style={{ width: '100%' }}
                                />
                            </Space>
                            <Space
                                direction={'vertical'}
                                style={{ width: '100%' }}
                            >
                                <Text>Сфера деятельности</Text>
                                <Select
                                    value={state?.activityField}
                                    onChange={(value) =>
                                        setState((state) => ({
                                            ...state!,
                                            activityField: value,
                                        }))
                                    }
                                    options={activityFieldOptions}
                                    style={{ width: '100%' }}
                                />
                                <Text>Семейное положение</Text>
                                <Select
                                    value={state?.maritalStatus}
                                    onChange={(value) =>
                                        setState((state) => ({
                                            ...state!,
                                            maritalStatus: value,
                                        }))
                                    }
                                    options={maritalStatusOptions}
                                    style={{ width: '100%' }}
                                />
                            </Space>
                            <Space
                                direction={'vertical'}
                                style={{ width: '100%' }}
                            >
                                <Text>Жилищные условия</Text>
                                <Select
                                    value={state?.housingConditions}
                                    onChange={(value) =>
                                        setState((state) => ({
                                            ...state!,
                                            housingConditions: value,
                                        }))
                                    }
                                    options={housingConditionOptions}
                                    style={{ width: '100%' }}
                                />
                                <Text>Количество детей до 18 лет</Text>
                                <Select
                                    value={state?.minorChildren}
                                    onChange={(value) =>
                                        setState((state) => ({
                                            ...state!,
                                            minorChildren: value,
                                        }))
                                    }
                                    options={minorChildrenOptions}
                                    style={{ width: '100%' }}
                                />
                            </Space>
                        </Space>
                    </Space>
                    <Button
                        disabled={!isValid}
                        type={'primary'}
                        style={{ width: 150 }}
                        onClick={saveHandler}
                    >
                        Сохранить
                    </Button>
                </>
            )}
        </div>
    )
}
