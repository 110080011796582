import { throwNotification } from '../Notification'
import { NoticeTypes } from '../../@types/notifications'
import { Dictionary } from '../../@types/common'

export const throwErrorNotification = (
    title?: string,
    message?: string | Dictionary<string[]>,
    duration?: number
) => {
    if (title) {
        return throwNotification(NoticeTypes.Error, message, title, duration)
    } else {
        return throwNotification(NoticeTypes.Error, 'Something went wrong!')
    }
}

export const throwSuccessNotification = (
    title?: string,
    message?: string | Dictionary<string[]>,
    duration?: number
) => {
    if (title) {
        return throwNotification(NoticeTypes.Success, message, title, duration)
    } else {
        return throwNotification(NoticeTypes.Success, 'Something went wrong!')
    }
}
