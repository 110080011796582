import React, { useRef } from 'react'
import { Table, TableProps, Typography } from 'antd'
import styles from './ResponsiveTable.module.scss'
import { useMobile } from '../../utils/contexts'
import { ColumnType } from 'rc-table'
import { AnyObject } from 'antd/es/_util/type'

export function ResponsiveTable<T extends AnyObject>(props: TableProps<T>) {
    const isMobile = useMobile()
    const ref = useRef<HTMLDivElement>(null)

    const columns: ColumnType<T>[] = (props.columns as ColumnType<T>[]) || []

    return (
        <div
            ref={ref}
            className={isMobile ? styles.wrapperMobile : styles.wrapper}
        >
            {isMobile && columns && props.dataSource ? (
                props.dataSource.map((x, index) => (
                    <div key={index} className={styles.tile}>
                        {columns.map((column, index) => (
                            <div key={column.key} className={styles.listRow}>
                                <Typography.Text>
                                    {column.title}
                                </Typography.Text>
                                <Typography.Text className={styles.cell}>
                                    {(column.render?.(
                                        x[column.dataIndex as keyof T],
                                        x,
                                        index
                                    ) as React.ReactElement) ||
                                        x[column.dataIndex as keyof T]}
                                </Typography.Text>
                            </div>
                        ))}
                    </div>
                ))
            ) : (
                <Table {...props} />
            )}
        </div>
    )
}
